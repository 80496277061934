import axios, { AxiosInstance, AxiosPromise } from 'axios';
// import Error from 'next/error';
import { IAxiosElement } from '../types';
import { loginRequest } from '../service/authConfig';
import msalInstance from '../service/msalService';

async function RequestAccessToken() {
    const accounts = msalInstance.getAllAccounts();
    const request = {
        ...loginRequest,
        account: accounts[0],
    };
    // Silently acquires an access token which
    // is then attached to a request for Microsoft Graph data
    return msalInstance.acquireTokenSilent(request).then((response: { accessToken: string }) => {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('accessToken', response.accessToken);
        return response.accessToken
        // setIsAuthenticated(true);
    }).catch((e: any) => {
        localStorage.clear();
        sessionStorage.clear();
        console.log(e);
        msalInstance.acquireTokenPopup(request).then((response: { accessToken: string }) => {
            localStorage.setItem('accessToken', '');
            localStorage.setItem('accessToken', response.accessToken);
            // setIsAuthenticated(true);
            return response.accessToken
        });
    });
}

async function ExtractData(axiosPromise: AxiosPromise) {

    const results = await axiosPromise;
    if (typeof results.headers['x-total-count'] !== 'undefined') {
        return {
            items: results.data,
            count: results.headers['x-total-count'],
        };
    }
    return results.data;
}

// eslint-disable-next-line consistent-return
async function getTokenHeader() {
    // Want to make sure that tokens can be called without getting the error for unauthenticated
    if (!window.location.href.includes('/login')) {
        console.log('Using local token')
        const localToken = await RequestAccessToken()
        return { Authorization: `Bearer ${localToken}` };
    }
    else if (typeof localStorage !== 'undefined') {
        console.log('Using storage token')
        const token = localStorage.getItem('accessToken');
        if (token !== undefined && token !== null) {
            return { Authorization: `Bearer ${token}` };
        }
    }
    return { Authorization: '' };
}

const getClient = async () => {
    const client: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-type': 'application/json',
            ...await getTokenHeader(),
        },
    });
    client.defaults.headers.common = {
        ...client.defaults.headers.common,
        ...await getTokenHeader()
    }
    client.interceptors.response.use((response) => response, (error) => {
        console.log('error', error);
        return Promise.reject(error);
    });
    return client;
};

const http = {
    client: getClient(),
    async get<T extends IAxiosElement>(dataArg: T) {
        const { url, config = {} } = dataArg;
        return ExtractData((await getClient()).get(url, config));
    },
    async post<T extends IAxiosElement>(dataArg: T) {
        const { url, data, config = {} } = dataArg;
        return ExtractData((await getClient()).post(url, data, config));
    },
    async put<T extends IAxiosElement>(dataArg: T) {
        const { url, data, config } = dataArg;
        return ExtractData((await getClient()).put(url, data, config));
    },
    async delete<T extends IAxiosElement>(dataArg: T) {
        const { url, config } = dataArg;
        return (await getClient()).delete(url, config);
    },
};

export default http;