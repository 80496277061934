import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './service/msalService';
import Layout from './constant/layout';
import { navlinks } from './constant';
import { AppContextProvider } from './context/AppContext';

// Import Duet Date Picker
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import "react-toastify/dist/ReactToastify.css";

// ...
// Register Duet Date Picker
defineCustomElements(window);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (window.location.hash !== '') {
  console.log(`hash found${window.location.hash}`);
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AppContextProvider>
            <Layout navlinks={navlinks} >
              <App />
            </Layout>
          </AppContextProvider>
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
