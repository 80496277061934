import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { RdsButton, RdsFieldset, RdsInput, RdsSelect, RdsSkeleton, RdsTooltip } from '@ramboll/rds-react';
import { IMeetingMaterial, OptionObjectType, IMeetingMaterialLocation } from '../../../types';
import { dummyMeetingMaterial, dummyMeetingMaterialLocation, makeDateAccordingToSelectDate, makeOptionsForInput, returnSelectedValueForSelectList, makeDateReadable, findObjectById, getLocalDate } from '../../../constant';
import { useAppContext } from '../../../context/AppContext';
import Select from 'react-select';


type Props = {}
function MeetingMaterials(props: Props) {
  const navigate = useNavigate();
  const [material, setMeetingMaterial] = useState<IMeetingMaterial>(dummyMeetingMaterial)
  const [meetingOptions, setMeetingOptions] = useState<OptionObjectType[]>([])
  const [materialTypesOptions, setMeetingTypeOptions] = useState<OptionObjectType[]>([])
  const [localLocations, setLocalLocations] = useState<IMeetingMaterialLocation[]>([])
  const [modalOpen, setModalOpen] = useState(false);

  const { materialTypes, users } = useAppContext()

  const { selectedMeetingMaterialId, setSelectedMeetingMaterialId } = useAppContext();

  useEffect(() => {
    api().meeting.get().then((meetings) => {
      console.log('Getting the Material')
      if (meetings.length > 0) {
        setMeetingOptions(makeOptionsForInput(meetings, 'name', 'id'))
      }
    }).catch(() => {
      navigate('/error')
    })
    setMeetingTypeOptions(makeOptionsForInput(materialTypes, 'name', 'id'))
  }, [materialTypes])

  useEffect(() => {
    if (selectedMeetingMaterialId !== 0) {
      api().material.getById(selectedMeetingMaterialId).then((co) => {
        console.log('Getting the Meeting Material by Id')
        var locations = co.locations;
        setLocalLocations(locations)
        setMeetingMaterial(co)
        setMeetingMaterial((co) => ({
          ...co,
          generationDate: getLocalDate(co.generationDate)
        }));
        onSelectType(co.materialTypeId);
      }).catch(() => {
        navigate('/error')
      })
    }
  }, [])

  const onSelectType= ((id: number) => {
      console.log("type selected");
      const el = document.getElementById("locationsDiv");
      if(el!=null){
      if(id == 3) {
        el.style.display = 'block'
      } else {
        el.style.display = 'none'
        setMeetingMaterial((c) => ({
          ...c,
          locations: []
        }))
        setLocalLocations((sl) => ([]))
      }
    }
  })
  
  function addLocationToList(): void {
    setLocalLocations((c) => ([
      ...c,
      { ...dummyMeetingMaterialLocation}
    ]))
  }

  function addLocalToActualMaterial(la: IMeetingMaterialLocation): void {
    var newId = Math.max(...localLocations.map(o => o.id))+1;
    la.id = newId;
    setMeetingMaterial((c) => ({
      ...c,
      locations: [...material.locations, la]
    }))
  }

  function deleteLocalToActualMaterial(la: IMeetingMaterialLocation): void {
    setMeetingMaterial((c) => ({
      ...c,
      locations: [...material.locations.filter((ca) => ca !== la)]
    }))
    setLocalLocations((sl) => ([
      ...sl.filter((ca) => ca !== la)
    ]))
  }


  useEffect(() => {
    // api().material.get().then((me) => {
    //   console.log('Getting the Meetings')
    //   if (me.length > 0) {
    //     setMeetingMaterial(me[0])
    //   }
    //   // setProjects(po)
    //   // if (po.length > 0 && selectedProjectId === 0) {
    //   //   setSelectedProjectId(po[0].id)
    //   // }
    // }).catch(() => {
    //   navigate('/error')
    // })
  }, [])
  // const onMeetingMaterialSubmit = (() => {
  //   api().material.post(material).then((response) => {
  //     console.log(response)
  //   })
  // })

  const onMeetingMaterialSubmit = (() => {
    if (selectedMeetingMaterialId === 0) {
      api().material.post(material).then((response) => {
        console.log(response)
        navigate('/materials')
      })
    } else {
      api().material.edit(selectedMeetingMaterialId, material).then(() => {
        console.log('Meeting Material Edited')
        setTimeout(function () {
          navigate('/materials')
        }, 2000); //Time before execution
        // toast.success("Material Updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }).catch((e) => {
        console.log(e)
        // toast.error("Meeting Material NOT edited. Check all inputs and click Submit again", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      })
    }
  })

  return (
    <div>
      <h1>Meeting Materials</h1>
      <form>
        <RdsFieldset legend="Material Information" color="primary">
          <div className='p-flex'>
            {/* {!material.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            <RdsTooltip content="Enter the material name">
              <RdsInput
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Name'
                value={material?.name}
                onInput={(e: any) => {
                  setMeetingMaterial((me) => ({
                    ...me,
                    name: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Select the material type">
              <RdsSelect
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Type'
                // name='type'
                options={JSON.stringify(materialTypesOptions)}
                value={material.materialTypeId}
                onInput={(e: any) => {
                  setMeetingMaterial((me) => ({
                    ...me,
                    materialTypeId: Number(e.target.value),
                  }));
                  onSelectType(Number(e.target.value));
                }}
              />
            </RdsTooltip>
            {/* )} */}
          </div>
          <div className='p-flex'>
            {/* {!meeting.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            <RdsTooltip content="Enter the date the material was generated">
              <RdsInput
                style={{ width: '455px' }}
                className='p-margin-right-10'
                type="date"
                label='Date'
                //value={makeDateReadable(material?.generationDate)}
                value={makeDateAccordingToSelectDate(material?.generationDate)}
                onInput={(e: any) => {
                  var formattedDate = getLocalDate(e.target.value);
                  setMeetingMaterial((me) => ({
                    ...me,
                    //generationDate: new Date(e.target.value).toISOString(),
                    generationDate: formattedDate.toString()
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the version of the material">
              <RdsInput
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Version'
                value={material?.version}
                onInput={(e: any) => {
                  setMeetingMaterial((me) => ({
                    ...me,
                    version: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            {/* )} */}
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Enter the file path of the material">
              <RdsInput
                style={{ width: '920px' }}
                className='p-margin-right-10'
                label='File Location'
                value={material?.fileLocation}
                onInput={(e: any) => {
                  setMeetingMaterial((me) => ({
                    ...me,
                    fileLocation: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
          </div>
          <RdsTooltip content="Select associated meeting(s)">
            <label className="check-container">
              Meetings
              <div style={{ width: '455px' }}>
                <Select
                  options={meetingOptions}
                  value={returnSelectedValueForSelectList(material.meetings, meetingOptions)}
                  isSearchable
                  isMulti
                  onChange={(e: any) => {
                    const listOfMeetings: number[] = []
                    for (let index = 0; index < e.length; index++) {
                      listOfMeetings.push(e[index].value);
                    }
                    setMeetingMaterial((c) => ({
                      ...c,
                      meetings: listOfMeetings
                    }))
                  }}
                />
              </div>
            </label>
          </RdsTooltip>
        </RdsFieldset>
        <div id="locationsDiv" style={{display: "none"}}>
          <RdsFieldset legend="Placement Locations" color="primary">
              {localLocations
                // .sort((a, b) => a.actionId - b.commentId)
                .map((la) => (
                  <div className='p-flex p-crossaxis-center'>
                    <RdsInput
                      style={{ width: '455px' }}
                      className='p-margin-right-10'
                      label='Location'
                      disabled={la.id !== 0}
                      value={la?.location}
                      onInput={(e: any) => {
                          setLocalLocations((sla) => ([
                            ...localLocations.filter((l) => l !== la),
                            { ...la, location: e.target.value }
                          ]))
                          console.log("location")
                          if (la.id !==0) {
                            setMeetingMaterial((mm) => ({
                              ...mm,
                              locations: [
                                ...material.locations.filter((l) => l !== la),
                                { ...la, location: e.target.value }
                              ]
                            }))
                          }
                        }
                      } 
                    />

                    {(!material.locations.find((ac) => ac.id === la.id))
                      ? < RdsButton key={la.id} variant='primary' onClick={() => addLocalToActualMaterial(la)}>Save</RdsButton>
                      : < RdsButton key={la.id} variant='danger' className='' onClick={() => deleteLocalToActualMaterial(la)}>Delete</RdsButton>}
                  </div>
                ))
              }
              <RdsButton onClick={addLocationToList}>New Location</RdsButton>
            </RdsFieldset>
          </div>
        <RdsFieldset legend="" color="primary"/>
      </form>
      <RdsButton variant='primary' className='p-margin-right-10' onClick={onMeetingMaterialSubmit}>{selectedMeetingMaterialId > 0 ? 'Save Changes' : 'Submit'}</RdsButton>
      <RdsButton variant='secondary' onClick={() => { navigate('/materials') }}>Cancel</RdsButton>
      {selectedMeetingMaterialId > 0 ? <div>Record Last Updated: {makeDateReadable(material?.timestamp)} by {findObjectById(users, material.userId) ? findObjectById(users, material.userId).email : ""}</div> : <div></div>}
      <br></br>
    </div>
  )
}
export default MeetingMaterials