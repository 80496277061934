import React from 'react';
import {
  RdsButton,
  RdsDropdown,
  RdsIcon,
  RdsMenu,
  RdsInput,
  RdsSelect,
} from '@ramboll/rds-react';
import { OptionObjectType, IComment } from '../../../types';
import './filter.scss';

export function filterBase(
  children: React.ReactNode,
  headerTitle: string,
  filter: string,
) {
  return (
    <div className="div__th">
      <div>
        {headerTitle}
      </div>
      <div>
        <RdsDropdown>
          <RdsButton slot="trigger" variant={filter ? 'secondary' : 'tertiary'} size="small" aria-labelledby="projectTitle">
            <RdsIcon name="menu-kebab" size="small">
              <span className="r-visually-hidden">Actions</span>
            </RdsIcon>
          </RdsButton>
          <RdsMenu>
            <div className="r-pt-m r-pb-m r-pl-xxl r-pr-xxl">
              {children}
            </div>
          </RdsMenu>
        </RdsDropdown>
      </div>
    </div>
  );
}
/**
 * @param {any} filterFunction : filter function
 * @param {string} columnName : Name of the column needed for the filterFunction
 * @param label : Optional label name, if different from "Search"
 */
export function filterBar(
  filterFunction: any,
  columnName: keyof IComment,
  label = 'Search',
) {
  return (
    <RdsInput
      label={label}
      name={label}
      // value={workLoadProjection.comment}
      onInput={(e: any) => filterFunction(e.target.value, columnName)}
    />
  );
}

export function filterSelect(
  filterFunction: any,
  columnName: keyof IComment,
  value: number,
  options: OptionObjectType[],
) {
  return (
    <RdsSelect
      label="Label"
      name="name"
      options={JSON.stringify(options)}
      value={value}
      onInput={(e: any) => {
        filterFunction(e, columnName, options);
      }}
    />
  );
}