import _ from 'lodash'
import { IComment, ICommentAction, IMeeting, IMeetingMaterial, IExternalMeeting, IMeetingNotification, IUser, OptionObjectType,
  OptionObjectTypeBoolean,ITableFilters, TableFilters,Pagination, IMeetingMaterialLocation } from "../types";
import Comments from '../views/comments';
import Meetings from '../views/meetings';
import Material from '../views/material';
import Externals from '../views/externals';
import Dashboard from '../views/dashboard';

export const findObjectById = <T>(targetList: T[], id: number): any => {
  const objectById = _.find(targetList, { id: id })

  if (objectById) {
    return objectById
  }
  return false
}

/**
* @param {T[]} data Array of data the needs to be put in options
* @param {string} attributeLabel attribute for label for options object
* @param {string} attributeValue attribute for value for options object
* @param {boolean} emptyOptionWanted boolean to know if empty Option is needed
* @returns {OptionObjectType[]}
*/
export const makeOptionsForInput = (<T>
  (data: T[],
    attributeLabel: string,
    attributeValue: string,
    emptyOptionNeeded = true): OptionObjectType[] => {
  const options: OptionObjectType[] = [];
  if (emptyOptionNeeded) options.push({ label: '', value: -999 });
  data.forEach((el: any) => {
    options.push({ label: el[attributeLabel], value: el[attributeValue] });
  });
  options.sort((a, b) => a.label.localeCompare(b.label));
  return options;
});

export const makeOptionsForInputBoolean = () => {
  const options: OptionObjectTypeBoolean[] = [];
  options.push({ label: "Yes", value: true });
  options.push({ label: "No", value: false });
  return options;
}

export const makeDateReadable = (date: string) => {
  if (date === null) {
    return 'TBD'
  }
  return new Date(date).toDateString()//toLocaleDateString('en-CA')  //
}

export const makeDateTimeReadable = (date: string) => {
  if (date === null) {
    return 'TBD'
  }
  return new Date(date).toString()//toLocaleString('en-CA')
}

export const makeDateAccordingToSelectDate = (date: string) => {
  if(date === null) {
    return ''
  }
  date = date.split(':').splice(0, 2).join(':')
  return date.replace('T', ' ')
}

export const makeDate = (date: string) => {
  if(date === null) {
    return ''
  }
  date = date.split(':').splice(0, 2).join(':')
  return date.replace('T', ' ').substring(0, 10)
}

export const makeTime = (time: string) => {
  if(time === null) {
    return ''
  }
  time = time.split(':').splice(0, 2).join(':')
  return time.replace('T', ' ').substring(11, 16)
}

export const makeDateTime = (date: string) => {
  // console.log("Datetime unformatted");
  // console.log(date);
  // console.log(date.split(':'));
  // date = date.split(':').splice(0, 1).join(':')
  // console.log("Datetime formatted");
  // console.log(date);
  if(date === null) {
    return ''
  }
  return date.replace('T', ' ').substring(0, 16)
}


export const getLocalDateTime = (value: string) => {
    if(value === "" || value === null) { 
      return value
    } 
    else 
    {
      //console.log(value);
      ////const offset = new Date().getTimezoneOffset() * 1000 * 60
      //console.log(offset);
      ////const offsetDate = new Date(value).valueOf() - offset
      //console.log(offsetDate);
      ////const date = new Date(offsetDate).toISOString()
      //console.log(date);
      //console.log(date.substring(0, 16) + ":00.000");
      ////return date.substring(0, 16) + ":00.000"
      return value.substring(0, 16) + ":00.000"
    }
} 

export const getLocalDate = (value: string) => {
  if(value === "" || value === null) { 
    return value
  } 
  else 
  {
    //console.log(value);
    // const offset = new Date().getTimezoneOffset() * 1000 * 60
    // console.log(offset);
    // const offsetDate = new Date(value).valueOf() - offset
    // console.log(offsetDate);
    // const date = new Date(offsetDate).toISOString()
    // console.log(date);
    // console.log(date.substring(0, 10));
    return value.substring(0, 10)
  }
} 

export const getLocalTime = (value: string) => {
  if(value === "" || value === null) { 
    return value
  } 
  else 
  {
    return value.substring(11, 16)
  }
} 

export const returnSelectedValueForSelectList = (attribute: any, OptionList: OptionObjectType[]) => {
  if (attribute) {
    const value = []
    for (let index = 0; index < attribute.length; index++) {
      const element = attribute[index];
      value.push(OptionList.find((c) => c.value === element))
    }
    return value
  }
  return []
}

export const dummyUser: IUser = {
  id: 0,
  name: '',
  lastName: '',
  email: '',
  userRoleId: 0
}

export const dummyMeeting: IMeeting = {
  id: 0,
  name: '',
  typeId: 0,
  date: '',
  time: '',
  location: '',
  attendeeCount: null,
  notificationMethodId: 0,
  notificationDistributionDate: '',
  notificationRecipientCount: 0,
  userId: 0,
  timestamp: '',
  stakeholders: [],
  notifications: [],
  notificationIds: [],
  materials: []
}

export const dummyComment: IComment = {
  id: 0,
  meetingId: 0,
  comment: '',
  commenter: '',
  commentReceivedDate: '',
  stakeholderId: 0,
  commentMethodId: 0,
  issueId: 0,
  userId: 0,
  timestamp: '',
  actions: [],
  actionId: 0,
  contactInformation: '',
  response: '',
  lastActionId: 0,
  lastActionDate: '',
  lastActionAssignee: 0
}

export const dummyCommentAction: ICommentAction = {
  commentId: 0,
  actionId: 0,
  id: 0,
  actionDate: makeDateAccordingToSelectDate(getLocalDate(new Date().toISOString())),
  userId: 0
}

export const dummyMeetingMaterial: IMeetingMaterial = {
  id: 0,
  name: '',
  materialTypeId: 0,
  fileLocation: '',
  generationDate: '',
  version: '',
  userId: 0,
  timestamp: '',
  meetings: [],
  nameVersion: '',
  locations: [],
  locationsString: []
}

export const dummyMeetingNotification: IMeetingNotification = {
  meetingId: 0,
  notificationMethodId: 1,
  id: 0,
  notificationDistributionDate: '',
  notificationRecipientCount: null
}

export const dummyMeetingMaterialLocation: IMeetingMaterialLocation = {
  meetingMaterialId: 0,
  location: '',
  id: 0
}

export const dummyExternalMeeting: IExternalMeeting = {
  id: 0,
  name: '',
  date: '',
  formatId: 0,
  host: '',
  representatives: '',
  materialPath: '',
  writtenComment: false,
  writtenCommentPath: '',
  oralComment: false,
  oralCommentPath: '',
  userId: 0,
  timestamp: '',
  issues: []
}

// export const dummyTableFilters: ITableFilters = {
//   includes: {},
//   equals: {},
//   greater: {},
//   lesser: {},
// };

export const navlinks = [
  { name: 'Dashboard', url: '/', element: Dashboard }, 
  { name: 'Meetings', url: '/meetings', element: Meetings },
  { name: 'Comments', url: '/comments', element: Comments },
  { name: 'Meeting Materials', url: '/materials', element: Material },
  { name: 'External Meetings', url: '/externalmeetings', element: Externals },
  //{ name: 'Home', url: '/dashboard', element: Dashboard }, 
  // { name: 'Events', url: '/events', element: Events },
  // { name: 'Gantt Chart', url: '/ganttchart', element: GanttChart },
  // { name: 'Info', url: '/info', element: Info }
]

export const dummyTableFilters: TableFilters = {
  includes: {},
  equals: {},
  greater: {},
  lesser: {},
};

export const dummyPagination: Pagination = {
  start: 0,
  end: 100,
  limit: 100,
  numberOfPages: 5,
  selectedPage: 0,
};

export const pageOptions = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100},
  { label: 200, value: 200 },
  { label: 500, value: 500 },
];