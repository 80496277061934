import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { IComment, TableFilters, Pagination, OptionObjectType } from '../../types';
import { findObjectById, makeDateReadable, makeDate, dummyTableFilters, dummyPagination, pageOptions, makeOptionsForInput } from '../../constant';
import { useAppContext } from '../../context/AppContext';
import { RdsButton, RdsIcon, RdsSpinner, RdsTag, RdsSelect } from '@ramboll/rds-react';
import Select from 'react-select';
import { filterBar, filterBase } from './filter';
import './comments.scss'
import Modal from '../../constant/modal/modal'
import { DropDown } from '../../constant/components/dropdown/DropDown';
import { DatePickerWrapperFilter } from './components/DatePickerFilter';

type Props = {}

function Comments(props: Props) {
  const navigate = useNavigate();
  const [comments, setComments] = useState<IComment[]>([]);
  const [stakeholderOptions, setStakeholderOptions] = useState<OptionObjectType[]>([])
  const [commentMethodOptions, setCommentMethodOptions] = useState<OptionObjectType[]>([])
  const [issueOptions, setIssueOptions] = useState<OptionObjectType[]>([])
  const [meetingOptions, setMeetingOptions] = useState<OptionObjectType[]>([])
  const [actionOptions, setActionOptions] = useState<OptionObjectType[]>([])
  const [userOptions, setUserOptions] = useState<OptionObjectType[]>([])
  const [originalList, setOriginalList] = useState<IComment[]>();
  const { meetings, issues, stakeholders, commentMethods, actions, setSelectedCommentId, users } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false)
  const [spinner, setSpinner] = useState(true);
  const [deleteId, setDeleteId] = useState(0)
  const [tableFilter, setTableFilter] = useState<TableFilters>(dummyTableFilters);
  const [pagination, setPagination] = useState<Pagination>(dummyPagination);
  const [buttonList, setButtonList] = useState<any[]>([]);

  useEffect(() => {
    api().comment.get().then((c) => {
      console.log('Getting the comments')
      setComments(c)
      setSelectedCommentId(0)
      // setting the data to the original list as backup
      setOriginalList(c);
      // setting the initial pagination selectors
      // setting data, data since we have only initial dataset
      runningListThroughPagination(c, c);
      setSpinner(false);
      console.log(c);
    }).catch(() => {
      navigate('/error')
    })
    api().info.stakeholders().then((sh) => setStakeholderOptions(makeOptionsForInput(sh, 'name', 'id')));
    api().info.commentMethod().then((cm) => setCommentMethodOptions(makeOptionsForInput(cm, 'name', 'id')));
    api().info.issue().then((is) => setIssueOptions(makeOptionsForInput(is, 'name', 'id')));
    api().info.users().then((u) => setUserOptions(makeOptionsForInput(u, 'email', 'id')));
    api().meeting.get().then((meetings) => {
      console.log('Getting the Meeting')
      if (meetings.length > 0) {
        setMeetingOptions(makeOptionsForInput(meetings, 'name', 'id'))
      }
    }).catch(() => {
      navigate('/error')
    })
    api().info.action().then((ac) => setActionOptions(makeOptionsForInput(ac, 'name', 'id')));
  }, [])

  const runningListThroughPagination = (
    workList: IComment[],
    original: IComment[],
  ) => {
    // CLoning the workLoadPlanAll
    const clonedWorkLoadPlanAll: IComment[] = JSON
      .parse(JSON.stringify(workList));

    // checking if filter is on or not and which list to select
    // When the filter is not on, we will use the original list
    // When filter is on, we will use the clonedWorkLoadPlanAll
    // This will make sure that we will have pagination when using
    // filters.
    const isFilterOn = (((Object.keys(tableFilter.equals)).length !== 0)
      || ((Object.keys(tableFilter.greater)).length !== 0)
      || ((Object.keys(tableFilter.includes)).length !== 0)
      || ((Object.keys(tableFilter.lesser)).length !== 0)) ? 1 : 0;
    const listSelected = isFilterOn ? clonedWorkLoadPlanAll : original;

    // Calculating the amount of pages we can get due to the limit
    // Calculation is dataLength / pagination limit + (if we have remainders) 1 (else) 0
    const dataLength = listSelected?.length;
    const numberOfPages = (Math.floor((dataLength ?? 0) / pagination.limit))
      + (((dataLength ?? 0) % pagination.limit) > 0
        ? 1
        : 0);

    // if the filter is on then the start will be from the first page
    // set start can be according to the page selected and limit
    // end is just (selectedPage +1) * limit
    const start = isFilterOn ? 0 : pagination.selectedPage * pagination.limit;
    const end = (pagination.selectedPage + 1) * pagination.limit;
    setPagination({
      ...pagination,
      numberOfPages,
      start,
      end,
    });
    // slicing base on the start and end value
    setComments([...listSelected.slice(start, end)]);
  };
  /**
   * This function just sets the pagination.selectedPage
   * @param {number} pageNumber
   */
  const pageChange = (pageNumber: number) => {
    setPagination({
      ...pagination,
      selectedPage: pageNumber,
    });
  };
  /**
   * This function after getting triggered uses the value of pagination.numberOfPages
   * To create a list of object that will be used to create buttons for pagination
   */
  const paginationRdsSelect = () => {
    if (pagination) {
      const newButtonList = [];
      for (let index = 0; index < pagination.numberOfPages; index += 1) {
        newButtonList.push({ label: index + 1, value: index });
      }
      setButtonList(newButtonList);
    }
  };

  const returnSelectedValueForSelectList = (attribute: any, OptionListToSearchFrom: OptionObjectType[]) => {
    if (attribute) {
      const value = []
      for (let index = 0; index < attribute.length; index++) {
        const element = attribute[index];
        value.push(OptionListToSearchFrom.find((c) => c.value === element))
      }
      return value
    }
    return []
  }

  function sortBasedOnDates(attribute: 'commentReceivedDate') { //| 'actionDate'
    setComments((t) => (
      t.sort((a, b) => {
        return new Date(a[attribute]).valueOf() - new Date(b[attribute]).valueOf()
      })
    ))
  }

  useEffect(() => {
    if (originalList) {
      // 1. create a clone of the original state stored
      let cloneOriginal: IComment[] = JSON.parse(JSON.stringify(originalList));
      // 2. Since its nested, let get the first
      // keys which are the operators (includes, equal, less, more)
      const operators: any = Object.keys(tableFilter);
      // 3. Start the loop for the top object
      for (let index = 0; index < operators.length; index += 1) {
        // 4. Get all columnNames and loop it
        const operator: keyof TableFilters = operators[index];
        const columnNames: any = Object.keys(tableFilter[operator]);
        for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
          // 5. Lets check which operator it is and change
          // the cloneOriginal according to the operator
          // 6. The cloneOriginal continues till the end of the loop
          const columnName: keyof IComment = columnNames[cidx];
          const valueFromColumn = (tableFilter[operator][columnName]);
          if (operator === 'includes') {

            // if (columnName === 'response') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  console.log("logical: " + (wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())));
                  return wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())
                }
                return false;
              });
            // } else {
            // cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
            //   .toString().includes(valueFromColumn));
            // }
          } else if (operator === 'equals') {
            if (columnName === 'stakeholderId' || columnName === 'commentMethodId' || columnName === 'issueId' || columnName === 'meetingId' || 
                columnName === 'actionId' || columnName === 'lastActionId'|| columnName === 'lastActionAssignee') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return valueFromColumn.includes(wpe[columnName]);
                }
                return false;
              });
            } else if (columnName === 'commentReceivedDate' || columnName === 'lastActionDate') {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                return wpe[columnName].split('T')[0] === valueFromColumn
              });
            } else {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => wpe[columnName]
              === valueFromColumn);
            } 
          } else if (operator === 'greater') {
            if (columnName === 'commentReceivedDate'|| columnName === 'lastActionDate') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null && wpe[columnName] !== undefined) {
                  console.log('wpe[columnName]: ' + wpe[columnName]);
                  return new Date(valueFromColumn) <= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else {
            cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
              > valueFromColumn);
            }
          } else if (operator === 'lesser') {
            if (columnName === 'commentReceivedDate'|| columnName === 'lastActionDate') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null && wpe[columnName] !== undefined) {
                  return new Date(valueFromColumn) >= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else {
            cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
              < valueFromColumn);
            }
          }
        }
      }
      // 7. Set cloneOriginal to workLoadPlanAll
      runningListThroughPagination(cloneOriginal, originalList);
    }
  }, [tableFilter]);

  useEffect(() => {
    runningListThroughPagination(comments ?? [], originalList ?? []);
  }, [
    pagination.limit,
    pagination.start,
    pagination.end,
    pagination.numberOfPages,
    pagination.selectedPage,
  ]);
  // useEffect when numberOfPagesChanges
  useEffect(() => {
    paginationRdsSelect();
  }, [pagination.numberOfPages]);

  const filterFunctionInclude = (value: any, columnName: keyof IComment) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    cloneFilter.includes[columnName] = value;
    setTableFilter({ ...cloneFilter });
  };

  const filterFunctionEqual = (
    value: any,
    columnName: keyof IComment,
    state: OptionObjectType[],
    isMulti: boolean = false
  ) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    if (!isMulti) {
      console.log('not multi');
      const valueFromDropDownList = state.find((c) => c.value === Number(value));
      cloneFilter.equals[columnName] = valueFromDropDownList?.value;
      setTableFilter({ ...cloneFilter });
    }
    else {
      console.log('multi');
      cloneFilter.equals[columnName] = (value);
      setTableFilter({ ...cloneFilter });
    }
  };

    /**
   * function for button reset, which deletes all the columnName from
   * all the operators in tableFilter
   */
  const reset = ((inputColName = '') => {
    // 1. Clone the tableFilter
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    // 2. Get all operators to loop
    const operators: any = Object.keys(cloneFilter);
    // 3. Start the loop for the top object
    for (let index = 0; index < operators.length; index += 1) {
      const operator: keyof TableFilters = operators[index];
      const columnNames: any = Object.keys(cloneFilter[operator]);
      // 4. Start the loop for the with the columnNames
      for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
        const columnName: keyof IComment = columnNames[cidx];
        // 5. Delete the specific columnName from the object if no inputColName supplied
        if (inputColName === '') {
          delete cloneFilter[operator][columnName];
          // 6. Else only delete the inputColName
        } else if (inputColName === columnName) {
          delete cloneFilter[operator][columnName];
        }
      }
    }
    setTableFilter({ ...cloneFilter });
  });

  /**
   * This function is set for setting selectedPage for two arrow between pagination buttons
   * @param {string} position - use "first" for the left arrow and "last" for the right arrow
   */
  const onArrowClick = (position: string) => {
    setPagination({
      ...pagination,
      selectedPage: position === 'first' ? 0 : pagination.numberOfPages - 1,
    });
  };


  const onConfirmDelete = (() => {
    console.log("Delete Id");
    console.log(deleteId);
    api().comment.deleteEventById(deleteId).then(() => {
      // toast.success("Project Deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      setComments((sm: IComment[]) => [
        ...sm.filter((s) => s.id !== deleteId)
      ])
    })
    setModalOpen(false);
  })

  const onClickDelete = ((commentId: number) => {
    console.log("click delete");
    onDialogToggled({ detail: modalOpen });
    setDeleteId(commentId);
    console.log(deleteId);
  })

  const onDialogToggled = ((e: { detail: boolean }) => {
    setModalOpen(!e.detail)
  })


  return (
    <div >
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>
        <h1>Comments</h1>
        <div>
        <RdsButton variant="secondary" onClick={() => reset()} style={{ marginRight: "10px" }}>Reset Filter</RdsButton>
        <RdsButton onClick={() => { navigate('/comments/create') }}>Create Comment Record</RdsButton>
        </div>
      </div>

      <Modal
        open={modalOpen}
        variant={"danger"}
        size={"small"}
        label={"Delete Comment?"}
        onDialogToggled={onDialogToggled}
      >
        <div className='p-flex p-flex-column'>
          {/* {label} */}
          <div slot="footer" className="r-flex r-gap-xs">
            <RdsButton variant="secondary" onClick={() => onDialogToggled({ detail: true })} >Cancel</RdsButton>
            <RdsButton variant="danger" onClick={() => {onConfirmDelete()}}>Delete</RdsButton>
          </div>
        </div>
      </Modal>

      <main className="container__planner_main  content-area">
        <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{ minHeight: '450px' }}>
          <thead className="thead-fixed">
            <tr>
              {/* <th align ="left">Comment</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'comment', 'Comment')}
                    </>,
                    'Comment',
                    tableFilter?.includes?.comment,
                  )
                }
              </th>
              {/* <th align ="left">Response</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'response', 'Response')}
                    </>,
                    'Response',
                    tableFilter?.includes?.response,
                  )
                }
              </th>
              {/* <th>Commenter</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'commenter', 'Commenter')}
                    </>,
                    'Commenter',
                    tableFilter?.includes?.commenter,
                  )
                }
              </th>
              {/* <th>Contact Information</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'contactInformation', 'Contact Information')}
                    </>,
                    'Contact Information',
                    tableFilter?.includes?.contactInformation,
                  )
                }
              </th>
              {/* <th>Stakeholder</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={stakeholderOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.stakeholderId, stakeholderOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'stakeholderId', stakeholderOptions, true);
                                }
                                else {
                                  reset('stakeholderId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('stakeholderId')}>Reset</RdsButton>
                        </>,
                        'Stakeholder',
                        tableFilter?.equals?.stakeholderId,
                        )
                      }
              </th>
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={commentMethodOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.commentMethodId, commentMethodOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'commentMethodId', commentMethodOptions, true);
                                }
                                else {
                                  reset('commentMethodId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('commentMethodId')}>Reset</RdsButton>
                        </>,
                        'Comment Method',
                        tableFilter?.equals?.commentMethodId,
                        )
                      }
              </th>
              {/* <th>Comment Method</th> */}
              <th>
                {
                  DropDown(
                    <div>
                      <label>Received Date</label>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="FROM"
                          attribute='commentReceivedDate'
                          defaultValue={tableFilter?.greater?.commentReceivedDate}
                          condition='greater'
                        />
                      </div>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="TO"
                          attribute='commentReceivedDate'
                          defaultValue={tableFilter?.lesser?.commentReceivedDate}
                          condition='lesser'
                        />
                      </div>
                      <div>
                        {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                        <RdsButton onClick={() => reset('commentReceivedDate')}>Reset</RdsButton>
                        {/* <RdsButton className='p-margin-left-10' onClick={() => sortBasedOnDates('commentReceivedDate')}>Sort</RdsButton> */}
                      </div>
                    </div>,
                    'Received Date',
                    tableFilter?.greater?.commentReceivedDate,
                    tableFilter?.lesser?.commentReceivedDate,
                  )
                }
              </th>
              {/* <th>Received Date</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={issueOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.issueId, issueOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'issueId', issueOptions, true);
                                }
                                else {
                                  reset('issueId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('issueId')}>Reset</RdsButton>
                        </>,
                        'Issue',
                        tableFilter?.equals?.issueId,
                        )
                      }
              </th>
              {/* <th>Issue</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={meetingOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.meetingId, meetingOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'meetingId', meetingOptions, true);
                                }
                                else {
                                  reset('meetingId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('meetingId')}>Reset</RdsButton>
                        </>,
                        'Meeting',
                        tableFilter?.equals?.meetingId,
                        )
                      }
              </th>
              {/* <th>Meeting</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={actionOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.lastActionId, actionOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'lastActionId', actionOptions, true);
                                }
                                else {
                                  reset('lastActionId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('lastActionId')}>Reset</RdsButton>
                        </>,
                        'Last Action',
                        tableFilter?.equals?.lastActionId,
                        )
                      }
              </th>
              {/* <th>Last Action</th> */}
              <th>
                {
                  DropDown(
                    <div>
                      <label>Last Action Date</label>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="FROM"
                          attribute='lastActionDate'
                          defaultValue={tableFilter?.greater?.lastActionDate}
                          condition='greater'
                        />
                      </div>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="TO"
                          attribute='lastActionDate'
                          defaultValue={tableFilter?.lesser?.lastActionDate}
                          condition='lesser'
                        />
                      </div>
                      <div>
                        {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                        <RdsButton onClick={() => reset('lastActionDate')}>Reset</RdsButton>
                        {/* <RdsButton className='p-margin-left-10' onClick={() => sortBasedOnDates('lastActionDate')}>Sort</RdsButton> */}
                      </div>
                    </div>,
                    'Last Action Date',
                    tableFilter?.greater?.lastActionDate,
                    tableFilter?.lesser?.lastActionDate,
                  )
                }
              </th>
              {/* <th>Last Action Date</th> */}
              {/* <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'action', 'Assignee')}
                    </>,
                    'Comment',
                    tableFilter?.includes?.comment,
                  )
                }
              </th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={userOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.lastActionAssignee, userOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'lastActionAssignee', userOptions, true);
                                }
                                else {
                                  reset('lastActionAssignee')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('lastActionAssignee')}>Reset</RdsButton>
                        </>,
                        'Last Action Assignee',
                        tableFilter?.equals?.lastActionAssignee,
                        )
                      }
              </th>
              {/* <th>Assignee</th> */}
              <th></th>
            </tr>
          </thead>
          {comments.length > 0 ?
            (<tbody>
              {
                comments
                  .sort((a, b) => a.id - b.id)
                  .map((co) => (
                    <tr key={co.id}>
                      <td className="td-max" align ="left">{co.comment}</td>
                      <td className="td-max2" align ="left">{co.response}</td>
                      <td>{co.commenter}</td>
                      <td>{co.contactInformation}</td>
                      <td>{findObjectById(stakeholders, co.stakeholderId) ? findObjectById(stakeholders, co.stakeholderId).name : ""}</td>
                      <td>{findObjectById(commentMethods, co.commentMethodId) ? findObjectById(commentMethods, co.commentMethodId).name : ""}</td>
                      <td>{makeDate(co.commentReceivedDate)}</td>
                      {/* <td>{makeDateReadable(co.commentReceivedDate)}</td> */}
                      <td>{findObjectById(issues, co.issueId) ? findObjectById(issues, co.issueId).name : ""}</td>
                      <td>{findObjectById(meetings, co.meetingId) ? findObjectById(meetings, co.meetingId).name : ""}</td>
                      <td>{findObjectById(actions, co.lastActionId) ? findObjectById(actions, co.lastActionId).name : ""}</td>
                      <td>{co.lastActionDate ? makeDate(co.lastActionDate) : ""}</td>
                      <td>{findObjectById(users, co.lastActionAssignee) ? findObjectById(users, co.lastActionAssignee).email : ""}</td>
                      {/* {co.actions && co.actions.length > 0 ? 
                        (
                          <td>
                            {co.actions.map((action, index) => (
                              <div key={`${action}-${index}`}>
                                {
                                  (action.actionDate ?  makeDate(action.actionDate) : "") 
                                }                      
                              </div>
                            ))}
                          </td>
                        )
                         : 
                        (<td></td>)
                      }
                      {co.actions && co.actions.length > 0 ? 
                        (
                          <td>
                            {co.actions.map((action, index) => (
                              <div key={`${action}-${index}`}>
                                {
                                  findObjectById(users, action.userId) ? findObjectById(users, action.userId).email  : ""
                                }                      
                              </div>
                            ))}
                          </td>
                        )
                         : 
                        (<td></td>)
                      } */}
                      {/* {co.actions && co.actions.length > 0 ? 
                        (
                          <td>
                            {co.actions.map((action, index) => (
                              <div key={`${action}-${index}`}>
                                {
                                  (findObjectById(actions, action.actionId) ?
                                  findObjectById(actions, action.actionId).name : "") + " (" +
                                  (action.actionDate ?
                                    makeDate(action.actionDate) : "") +
                                    //makeDateReadable(action.actionDate) : "") +
                                  (findObjectById(users, action.userId) ? ", " +
                                  findObjectById(users, action.userId).email  : "") +
                                  ") "
                                }                      
                              </div>
                            ))}
                          </td>
                        )
                         : 
                        (<td></td>)

                      } */}
                      {/* <td>{co.actionIds}</td> */}
                      {/* {co.actionIds && co.actionIds.length > 0 ? 
                        (<td>
                          {co.actionIds.map((actionId, index) => (
                            <div key={`${actionId}-${index}`}>
                              {findObjectById(actions, actionId) ?
                              findObjectById(actions, actionId).name : ""}
                            </div>
                          ))}
                        </td>)
                        : 
                        (<td></td>)
                      } */}
                      {/* <td>{makeDateReadable(co.timestamp)}</td>
                      <td>{findObjectById(users, co.userId) ? findObjectById(users, co.userId).email : ""}</td> */}
                      {/* <td>{co.userId}</td> */}
                      <td>
                        <div>
                          <div className="tooltip p-padding-top-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="primary"
                                size="small"
                                onClick={() => {
                                  //navigate('/projects/create'); setSelectedProjectId(po.id)
                                  setSelectedCommentId(co.id)
                                  navigate('/comments/create')
                                }}
                              >
                                <RdsIcon
                                  name="pencil-fill"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">Comment Edit</span>
                            </div>
                          </div>
                          <div className="tooltip p-padding-top-10 p-padding-left-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="danger"
                                size="small"
                                onClick={() => onClickDelete(co.id)} //me.id
                              >
                                <RdsIcon
                                  name="trash"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">Delete Comment</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>
                  ))
              }
            </tbody>)
            : <tbody>
              {spinner ? <RdsSpinner size="medium" color="primary">Loading Comments</RdsSpinner> : "No entries"}
            </tbody>
          }
        </table>

      </main>
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>

                <div style={{display: "flex", flexDirection: "row"}}>
                    <RdsButton size="medium" className="item" onClick={() => onArrowClick('first')}>&laquo;</RdsButton>
                    &nbsp;
                    {buttonList.map((bu) => ( //reverse()
                      <RdsButton size="medium" key={`rds-button-${bu.value}`} className="item" variant={(pagination.selectedPage !== undefined && Number(pagination.selectedPage) === bu.value) ? 'secondary' : 'primary'} onClick={() => pageChange(bu.value)}>{bu.label}</RdsButton>
                    ))}
                    &nbsp;
                    <RdsButton size="medium" className="item" onClick={() => onArrowClick('last')}>&raquo;</RdsButton>
                    &nbsp;
                    <RdsSelect
                    className="rds-select-custom"
                    options={JSON.stringify(pageOptions)}
                    value={pagination.limit}
                    onInput={(e: any) => {
                      setPagination({
                        ...pagination,
                        limit: Number(e.target.value),
                        selectedPage: 0,
                      });
                    }}
                    style={{height:"20px"}}
                    />
      

                  
                </div>
                {/* <div className="paginationInfo">
                  {`Showing items 
                ${(pagination.start + comments.length)} of 
                ${(((Object.keys(tableFilter.equals)).length !== 0)
                      || ((Object.keys(tableFilter.greater)).length !== 0)
                      || ((Object.keys(tableFilter.includes)).length !== 0)
                      || ((Object.keys(tableFilter.lesser)).length !== 0)) ? (comments.length) : originalList.length}`}
                </div> */}
      </div>
    </div>
  )
}

export default Comments
