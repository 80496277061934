import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import './navbar.scss';
import IconButton from '@mui/material/IconButton';
import Logo from '../../assets/images/ramboll-logo-cyan.svg';
import { RdsAvatar, RdsButton, RdsDropdown, RdsIcon, RdsMenu, RdsMenuItem, RdsSelect } from '@ramboll/rds-react';
import { INavlinks, OptionObjectType } from '../../types';
import { useAppContext } from '../../context/AppContext';
import { useMsal } from '@azure/msal-react';
import { makeOptionsForInput } from '..';


export type Props = {
  navlinks: INavlinks[]
}

const Navbar = ({ navlinks }: Props) => {
  const { instance } = useMsal();
  const { setIsAuthenticated, currentUser } = useAppContext();
  // const [projectOptions, setProjectOptions] = useState<OptionObjectType[]>([])
  const navigate = useNavigate();

  function navigateTo(url: string) {
    navigate(url);
  }

  function onClick(event: React.MouseEvent<HTMLElement>, url: string) {
    navigateTo(url)
  }

  function handleLogout(inst: any) {
    inst.logoutPopup().catch((e: any) => {
      console.error(e);
    });
    sessionStorage.clear();
    localStorage.clear();
    console.log('setting isauthenticated false on logout')
    setIsAuthenticated(false);
    navigate('/login')
  }

  // useEffect(() => {
  //   setProjectOptions(makeOptionsForInput(projects, 'name', 'id'))
  // }, [projects])

  return (
    <div className='navbar_container'>
      <div className="navbar">
        <IconButton className='logo' onClick={(e) => onClick(e, '/')}>
          <img alt="RambollLogo" src={Logo} height={55} width={135} />
        </IconButton>
        {navlinks.map((navlink) =>
          <RdsButton key={navlink.name} className='item' onClick={(e) => onClick(e, navlink.url)}>{navlink.name}</RdsButton>
        )}
      </div>
      <div className='navbar_mini_container'>
        {/* <div className='p-margin-top-20'>
          <RdsSelect
            //label='type'
            // name='type'
            className='item__container_cp'
            options={JSON.stringify(projectOptions)}
            value={projectOptions.find((p) => p.value === selectedProjectId)?.value}
            onInput={(e: any) => {
              setSelectedProjectId(Number(e.target.value))
            }}
          />
        </div> */}
        <RdsDropdown>
          <RdsButton size="small" slot="trigger">
            <RdsAvatar variant="secondary" size="large" className='item'>
              {currentUser && currentUser.email ? currentUser.email[0] : ""}
            </RdsAvatar>
            {currentUser && currentUser.email ? currentUser.email : ""}
            <span>
              <RdsIcon name="menu-meatball" size="small"></RdsIcon>
            </span>
          </RdsButton>
          <RdsMenu>
            {/* <RdsMenuItem>Account details</RdsMenuItem> */}
            <RdsMenuItem onClick={() => handleLogout(instance)}>Logout</RdsMenuItem>
          </RdsMenu>
        </RdsDropdown>
      </div>
    </div>
  )
}

export default Navbar;