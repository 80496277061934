import { IComment, IMeeting, IUser, IMeetingMaterial, IExternalMeeting, IAction, IMeetingType, INotificationMaterial, 
  IStakeholder, ICommentMethod, INotificationMethod, IIssue, IMeetingFormat, ICountSummary, IMeetingMaterialSummary, IMeetingSummary } from "../types"
import http from "./httpsCommon"

const api = () => ({
  get info() {
    return {
      users: async (): Promise<IUser[]> => http.get({ url: 'info/users' }),
      currentUser: async (): Promise<IUser[]> => http.get({ url: 'info/users/current' }),
      action: async (): Promise<IAction[]> => http.get({ url: 'info/actions' }),
      meetingType: async (): Promise<IMeetingType[]> => http.get({ url: 'info/meeting-types' }),
      notificationMethod: async (): Promise<INotificationMethod[]> => http.get({ url: 'info/notification-methods' }),
      issue: async (): Promise<IIssue[]> => http.get({ url: 'info/issue' }),
      meetingFormat: async (): Promise<IMeetingFormat[]> => http.get({ url: 'info/meeting-formats' }),
      stakeholders: async (): Promise<IStakeholder[]> => http.get({ url: 'info/stakeholders' }),
      commentMethod: async (): Promise<ICommentMethod[]> => http.get({ url: 'info/comment-methods' }),
      materialTypes: async (): Promise<IMeetingType[]> => http.get({ url: 'info/material-types' }),
    }
  },
  get dashboard() {
    return {
      commentIssueSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-comment-issue' }),
      commentIssueDateSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-comment-issue-date' }),
      commenterDateSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-commenter-date' }),
      commentMethodSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-comment-method' }),
      commentStakeholderSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-comment-stakeholder' }),
      commentActionSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-comment-actions' }), 
      meetingSummary: async (): Promise<IMeetingSummary[]> => http.get({url: 'dashboard/summary-meeting' }), 
      meetingDateSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-meeting-date' }), 
      meetingTypeSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-meeting-type' }), 
      meetingTimeSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-meeting-time' }), 
      meetingNotificationSummary: async (): Promise<ICountSummary[]> => http.get({url: 'dashboard/summary-meeting-notification' }), 
      meetingMaterialSummary: async (): Promise<IMeetingMaterialSummary[]> => http.get({url: 'dashboard/summary-meeting-materials' }), 
    }
  },
  get meeting() {
    return {
      get: async (): Promise<IMeeting[]> => http.get({ url: 'meeting' }),
      getById: async (id: number): Promise<IMeeting> => http.get({ url: `meeting/${id}` }),
      post: async (data: IMeeting): Promise<IMeeting> => http.post({ url: `meeting`, data }),
      edit: async (id: number, data: IMeeting): Promise<IMeeting> => http.put({ url: `meeting`, data }),
      deleteEventById: async (id: number) => http.delete({ url: `meeting/${id}` }),
    }
  },
  get comment() {
    return {
      get: async (): Promise<IComment[]> => http.get({ url: 'comment' }),
      getById: async (id: number): Promise<IComment> => http.get({ url: `comment/${id}` }),
      post: async (data: IComment): Promise<IComment> => http.post({ url: `comment`, data }),
      edit: async (id: number, data: IComment): Promise<IComment> => http.put({ url: `comment`, data }),
      deleteEventById: async (id: number) => http.delete({ url: `comment/${id}` }),
    }
  },
  get material() {
    return {
      get: async (): Promise<IMeetingMaterial[]> => http.get({ url: 'material' }),
      getById: async (id: number): Promise<IMeetingMaterial> => http.get({ url: `material/${id}` }),
      post: async (data: IMeetingMaterial): Promise<IMeetingMaterial> => http.post({ url: `material`, data }),
      edit: async (id: number, data: IMeetingMaterial): Promise<IMeetingMaterial> => http.put({ url: `material`, data }),
      deleteEventById: async (id: number) => http.delete({ url: `material/${id}` }),
    }
  },
  get external() {
    return {
      get: async (): Promise<IExternalMeeting[]> => http.get({ url: 'external' }),
      getById: async (id: number): Promise<IExternalMeeting> => http.get({ url: `external/${id}` }),
      post: async (data: IExternalMeeting): Promise<IExternalMeeting> => http.post({ url: `external`, data }),
      edit: async (id: number, data: IExternalMeeting): Promise<IExternalMeeting> => http.put({ url: `external`, data }),
      deleteEventById: async (id: number) => http.delete({ url: `external/${id}` }),
    }
  },
})

export default api