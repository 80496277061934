import React, { useEffect, useState } from 'react'
import { dummyComment, dummyTableFilters, makeDateReadable, makeDate, dummyPagination, pageOptions, makeOptionsForInput, makeOptionsForInputBoolean } from '../../constant'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
import { IExternalMeeting, IIssue, ITableFilters, TableFilters, Pagination, OptionObjectType, OptionObjectTypeBoolean } from '../../types'
import { findObjectById } from '../../constant';
import { filterBar, filterBase } from './filter';
import { useAppContext } from '../../context/AppContext';
import { RdsButton, RdsSpinner, RdsIcon, RdsSelect } from '@ramboll/rds-react'
import Modal from '../../constant/modal/modal'
import Select from 'react-select';
import { DropDown } from '../../constant/components/dropdown/DropDown';
import { DatePickerWrapperFilter } from './components/DatePickerFilter';

type Props = {}

const ExternalMeeting = (props: Props) => {
  const [tableFilter, setTableFilter] = useState<ITableFilters>(dummyTableFilters);
  const navigate = useNavigate();
  const [externals, setExternalMeetings] = useState<IExternalMeeting[]>([])
  const [originalList, setOriginalList] = useState<IExternalMeeting[]>();
  const { issues, meetingFormats } = useAppContext();
  const [issueOptions, setIssueOptions] = useState<OptionObjectType[]>([])
  const [meetingFormatOptions, setMeetingFormatOptions] = useState<OptionObjectType[]>([])
  const [booleanOptions, setBooleanOptions] = useState<OptionObjectTypeBoolean[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [spinner, setSpinner] = useState(true);
  const [checkBoxList, setCheckBoxList] = useState([
    { id: 1, name: "Name", status: true }
  ])

  const [pagination, setPagination] = useState<Pagination>(dummyPagination);
  const [buttonList, setButtonList] = useState<any[]>([]);

  const { setSelectedExternalMeetingId} = useAppContext();

  const runningListThroughPagination = (
    workList: IExternalMeeting[],
    original: IExternalMeeting[],
  ) => {
    // CLoning the workLoadPlanAll
    const clonedWorkLoadPlanAll: IExternalMeeting[] = JSON
      .parse(JSON.stringify(workList));

    // checking if filter is on or not and which list to select
    // When the filter is not on, we will use the original list
    // When filter is on, we will use the clonedWorkLoadPlanAll
    // This will make sure that we will have pagination when using
    // filters.
    const isFilterOn = (((Object.keys(tableFilter.equals)).length !== 0)
      || ((Object.keys(tableFilter.greater)).length !== 0)
      || ((Object.keys(tableFilter.includes)).length !== 0)
      || ((Object.keys(tableFilter.lesser)).length !== 0)) ? 1 : 0;
    const listSelected = isFilterOn ? clonedWorkLoadPlanAll : original;

    // Calculating the amount of pages we can get due to the limit
    // Calculation is dataLength / pagination limit + (if we have remainders) 1 (else) 0
    const dataLength = listSelected?.length;
    const numberOfPages = (Math.floor((dataLength ?? 0) / pagination.limit))
      + (((dataLength ?? 0) % pagination.limit) > 0
        ? 1
        : 0);

    // if the filter is on then the start will be from the first page
    // set start can be according to the page selected and limit
    // end is just (selectedPage +1) * limit
    const start = isFilterOn ? 0 : pagination.selectedPage * pagination.limit;
    const end = (pagination.selectedPage + 1) * pagination.limit;
    setPagination({
      ...pagination,
      numberOfPages,
      start,
      end,
    });
    // slicing base on the start and end value
    setExternalMeetings([...listSelected.slice(start, end)]);
  };
  /**
   * This function just sets the pagination.selectedPage
   * @param {number} pageNumber
   */
  const pageChange = (pageNumber: number) => {
    setPagination({
      ...pagination,
      selectedPage: pageNumber,
    });
  };
  /**
   * This function after getting triggered uses the value of pagination.numberOfPages
   * To create a list of object that will be used to create buttons for pagination
   */
  const paginationRdsSelect = () => {
    if (pagination) {
      const newButtonList = [];
      for (let index = 0; index < pagination.numberOfPages; index += 1) {
        newButtonList.push({ label: index + 1, value: index });
      }
      setButtonList(newButtonList);
    }
  };

  const returnSelectedValueForSelectList = (attribute: any, OptionListToSearchFrom: OptionObjectType[]) => {
    if (attribute) {
      const value = []
      for (let index = 0; index < attribute.length; index++) {
        const element = attribute[index];
        value.push(OptionListToSearchFrom.find((c) => c.value === element))
      }
      return value
    }
    return []
  }

  function sortBasedOnDates(attribute: 'date') {
    setExternalMeetings((t) => (
      t.sort((a, b) => {
        return new Date(a[attribute]).valueOf() - new Date(b[attribute]).valueOf()
      })
    ))
  }

  const returnSelectedValueForSelectListBoolean = (attribute: any, OptionListToSearchFrom: OptionObjectTypeBoolean[]) => {
    if (attribute) {
      const value = []
      for (let index = 0; index < attribute.length; index++) {
        const element = attribute[index];
        value.push(OptionListToSearchFrom.find((c) => c.value === element))
      }
      return value
    }
    return []
  }

  useEffect(() => {
    if (originalList) {
      // 1. create a clone of the original state stored
      let cloneOriginal: IExternalMeeting[] = JSON.parse(JSON.stringify(originalList));
      // 2. Since its nested, let get the first
      // keys which are the operators (includes, equal, less, more)
      const operators: any = Object.keys(tableFilter);
      // 3. Start the loop for the top object
      for (let index = 0; index < operators.length; index += 1) {
        // 4. Get all columnNames and loop it
        const operator: keyof TableFilters = operators[index];
        const columnNames: any = Object.keys(tableFilter[operator]);
        for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
          // 5. Lets check which operator it is and change
          // the cloneOriginal according to the operator
          // 6. The cloneOriginal continues till the end of the loop
          const columnName: keyof IExternalMeeting = columnNames[cidx];
          const valueFromColumn = (tableFilter[operator][columnName]);
          if (operator === 'includes') {

            // if (columnName === 'response') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  console.log("logical: " + (wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())));
                  return wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())
                }
                return false;
              });
            // } else {
            // cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
            //   .toString().includes(valueFromColumn));
            // }
          } else if (operator === 'equals') {
            if (columnName === 'formatId' ) {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return valueFromColumn.includes(wpe[columnName]);
                }
                return false;
              });
            } else if (columnName === 'issues') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return wpe[columnName].some(a => valueFromColumn.includes(a));
                }
                return false;
              });
            } else if (columnName === 'writtenComment' || columnName === 'oralComment' ) {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return valueFromColumn.includes(wpe[columnName]);
                }
                return false;
              });
            } else if (columnName === 'date') {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                return wpe[columnName].split('T')[0] === valueFromColumn
              });
            } else {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => wpe[columnName]
              === valueFromColumn);
            } 
          } else if (operator === 'greater') {
            if (columnName === 'date') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  return new Date(valueFromColumn) <= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else {
            cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
              > valueFromColumn);
            }
          } else if (operator === 'lesser') {
            if (columnName === 'date') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  return new Date(valueFromColumn) >= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else {
            cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
              < valueFromColumn);
            }
          }
        }
      }
      // 7. Set cloneOriginal to workLoadPlanAll
      runningListThroughPagination(cloneOriginal, originalList);
    }
  }, [tableFilter]);

  useEffect(() => {
    runningListThroughPagination(externals ?? [], originalList ?? []);
  }, [
    pagination.limit,
    pagination.start,
    pagination.end,
    pagination.numberOfPages,
    pagination.selectedPage,
  ]);
  // useEffect when numberOfPagesChanges
  useEffect(() => {
    paginationRdsSelect();
  }, [pagination.numberOfPages]);

  const filterFunctionInclude = (value: any, columnName: keyof IExternalMeeting) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    cloneFilter.includes[columnName] = value;
    setTableFilter({ ...cloneFilter });
  };

  const filterFunctionEqual = (
    value: any,
    columnName: keyof IExternalMeeting,
    state: OptionObjectType[],
    isMulti: boolean = false
  ) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    if (!isMulti) {
      console.log('not multi');
      const valueFromDropDownList = state.find((c) => c.value === Number(value));
      cloneFilter.equals[columnName] = valueFromDropDownList?.value;
      setTableFilter({ ...cloneFilter });
    }
    else {
      console.log('multi');
      cloneFilter.equals[columnName] = (value);
      setTableFilter({ ...cloneFilter });
      console.log(cloneFilter.equals[columnName]);
    }
  };

  const filterFunctionEqualBoolean = (
    value: any,
    columnName: keyof IExternalMeeting,
    state: OptionObjectTypeBoolean[],
    isMulti: boolean = true
  ) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    if (!isMulti) {
      console.log('not multi');
      const valueFromDropDownList = state.find((c) => c.value === value);
      cloneFilter.equals[columnName] = valueFromDropDownList?.value;
      setTableFilter({ ...cloneFilter });
    }
    else {
      console.log('multi');
      console.log(value);
      cloneFilter.equals[columnName] = (value);
      setTableFilter({ ...cloneFilter });
      console.log();
    }
  };

    /**
   * function for button reset, which deletes all the columnName from
   * all the operators in tableFilter
   */
  const reset = ((inputColName = '') => {
    // 1. Clone the tableFilter
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    // 2. Get all operators to loop
    const operators: any = Object.keys(cloneFilter);
    // 3. Start the loop for the top object
    for (let index = 0; index < operators.length; index += 1) {
      const operator: keyof TableFilters = operators[index];
      const columnNames: any = Object.keys(cloneFilter[operator]);
      // 4. Start the loop for the with the columnNames
      for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
        const columnName: keyof IExternalMeeting = columnNames[cidx];
        // 5. Delete the specific columnName from the object if no inputColName supplied
        if (inputColName === '') {
          delete cloneFilter[operator][columnName];
          // 6. Else only delete the inputColName
        } else if (inputColName === columnName) {
          delete cloneFilter[operator][columnName];
        }
      }
    }
    setTableFilter({ ...cloneFilter });
  });

  /**
   * This function is set for setting selectedPage for two arrow between pagination buttons
   * @param {string} position - use "first" for the left arrow and "last" for the right arrow
   */
  const onArrowClick = (position: string) => {
    setPagination({
      ...pagination,
      selectedPage: position === 'first' ? 0 : pagination.numberOfPages - 1,
    });
  };

  const onConfirmDelete = (() => {
    console.log("Delete Id");
    console.log(deleteId);
    api().external.deleteEventById(deleteId).then(() => {
      // toast.success("Project Deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      setExternalMeetings((sm: IExternalMeeting[]) => [
        ...sm.filter((s) => s.id !== deleteId)
      ])
    })
    setModalOpen(false);
  })

  // const onClickDelete = ((id: number) => {
  //   console.log("Delete Button");
  // })

  const onClickDelete = ((externalMeetingId: number) => {
    console.log("click delete");
    onDialogToggled({ detail: modalOpen });
    setDeleteId(externalMeetingId);
    console.log(deleteId);
  })

  const onDialogToggled = ((e: { detail: boolean }) => {
    setModalOpen(!e.detail)
  })


  useEffect(() => {
    api().external.get().then((ma) => {
      console.log('Getting all the external meetings')
      setSelectedExternalMeetingId(0)
      setExternalMeetings(ma)
      setOriginalList(ma);
      runningListThroughPagination(ma, ma);
      setSpinner(false);
    }).catch(() => {
      navigate('/error')
    })
  }, [])

  useEffect(() => {
    setMeetingFormatOptions(makeOptionsForInput(meetingFormats, 'name', 'id'))
    setIssueOptions(makeOptionsForInput(issues, 'name', 'id'))
  }, [meetingFormats, issues])

  useEffect(() => {
    setBooleanOptions(makeOptionsForInputBoolean())
  }, [externals])

  return (
    <div>
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>
        <h1>External Meetings</h1>
        <div>
          <RdsButton variant="secondary" onClick={() => reset()} style={{ marginRight: "10px" }}>Reset Filter</RdsButton>
          <RdsButton onClick={() => { navigate('/externalmeetings/create') }}>Create External Meeting Record</RdsButton>
        </div>
      </div>

      <Modal
        open={modalOpen}
        variant={"danger"}
        size={"small"}
        label={"Delete External Meeting?"}
        onDialogToggled={onDialogToggled}
      >
        <div className='p-flex p-flex-column'>
          {/* {label} */}
          <div slot="footer" className="r-flex r-gap-xs">
            <RdsButton variant="secondary" onClick={() => onDialogToggled({ detail: true })} >Cancel</RdsButton>
            <RdsButton variant="danger" onClick={() => {onConfirmDelete()}}>Delete</RdsButton>
          </div>
        </div>
      </Modal>

      <main className="container__planner_main content-area">
        <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{ minHeight: '450px' }}>
          <thead className="thead-fixed">
            <tr>
            <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'name', 'Name')}
                    </>,
                    'Meeting Name',
                    tableFilter?.includes?.name,
                  )
                }
              </th>
              <th>
                {
                  DropDown(
                    <div>
                      <label>Meeting Date</label>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="FROM"
                          attribute='date'
                          defaultValue={tableFilter?.greater?.date}
                          condition='greater'
                        />
                      </div>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="TO"
                          attribute='date'
                          defaultValue={tableFilter?.lesser?.date}
                          condition='lesser'
                        />
                      </div>
                      <div>
                        {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                        <RdsButton onClick={() => reset('date')}>Reset</RdsButton>
                        {/* <RdsButton className='p-margin-left-10' onClick={() => sortBasedOnDates('date')}>Sort</RdsButton> */}
                      </div>
                    </div>,
                    'Meeting Date',
                    tableFilter?.greater?.date,
                    tableFilter?.lesser?.date,
                  )
                }
              </th>
              {/* <th>Meeting Date</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={meetingFormatOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.formatId, meetingFormatOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'formatId', meetingFormatOptions, true);
                                }
                                else {
                                  reset('formatId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('formatId')}>Reset</RdsButton>
                        </>,
                        'Format',
                        tableFilter?.equals?.formatId,
                        )
                      }
              </th>
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'host', 'Host')}
                    </>,
                    'Meeting Host',
                    tableFilter?.includes?.host,
                  )
                }
              </th>
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'representatives', 'Representatives')}
                    </>,
                    'Cleco Representatives',
                    tableFilter?.includes?.representatives,
                  )
                }
              </th>
              <th>
                {
                  filterBase(
                    <>
                    <div style={{ minWidth: '200px' }}>
                      <Select
                        options={issueOptions}
                        value={returnSelectedValueForSelectList(tableFilter?.equals?.issues, issueOptions)}
                        isSearchable
                        isMulti={true}
                        onChange={(events) => {
                          if (events.length > 0) {
                            const selected = []
                            for (let index = 0; index < events.length; index++) {
                              const element = events[index];
                              if (element) {
                                selected.push(Number(element.value))
                              }
                            }
                            filterFunctionEqual(selected, 'issues', issueOptions, true);
                          }
                          else {
                            reset('issues')
                          }
                        }}
                      />
                    </div>
                    {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                    <RdsButton className='p-margin-top-20' onClick={() => reset('issues')}>Reset</RdsButton>
                  </>,
                  'Comment Topics',
                  tableFilter?.equals?.issues,
                  )
                }
              </th>
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={booleanOptions}
                              value={returnSelectedValueForSelectListBoolean(tableFilter?.equals?.writtenComment, booleanOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push((element.value))
                                    }
                                  }
                                  filterFunctionEqualBoolean(selected, 'writtenComment', booleanOptions, true);
                                }
                                else {
                                  reset('writtenComment')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('writtenComment')}>Reset</RdsButton>
                        </>,
                        'Cleco Written Comment?',
                        tableFilter?.equals?.writtenComment,
                        )
                      }
              </th>             
              {/* <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'writtenComment', 'Written Comment?')}
                    </>,
                    'Cleco Written Comment?',
                    tableFilter?.includes?.writtenComment,
                  )
                }
              </th> */}
               <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={booleanOptions}
                              value={returnSelectedValueForSelectListBoolean(tableFilter?.equals?.oralComment, booleanOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push((element.value))
                                    }
                                  }
                                  filterFunctionEqualBoolean(selected, 'oralComment', booleanOptions, true);
                                }
                                else {
                                  reset('oralComment')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('oralComment')}>Reset</RdsButton>
                        </>,
                        'Cleco Oral Comment?',
                        tableFilter?.equals?.oralComment,
                        )
                      }
              </th>      
              {/* <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'oralComment', 'Oral Comment?')}
                    </>,
                    'Cleco Oral Comment?',
                    tableFilter?.includes?.oralComment,
                  )
                }
              </th> */}
              <th></th>
            </tr>
          </thead>
          {externals.length > 0 ?
            (<tbody>
              {
                externals.map((me) => (
                  <tr key={me.id}>
                    <td align ="left">{me.name}</td>
                    <td>{makeDate(me.date)}</td>
                    <td>{findObjectById(meetingFormats, me.formatId) ? findObjectById(meetingFormats, me.formatId).name : ""}</td>
                    <td>{me.host}</td>
                    <td>{me.representatives}</td>
                    {me.issues && me.issues.length > 0 ? 
                        (<td>
                          {me.issues.map((issue, index) => (
                            <div key={`${issue}-${index}`}>
                              {findObjectById(issues, issue) ?
                              findObjectById(issues, issue).name : ""}
                            </div>
                          ))}
                        </td>)
                        : 
                        (<td></td>)
                    }
                    <td>{me.writtenComment ? "Yes" : "No"}</td>
                    <td>{me.oralComment ? "Yes" : "No"}</td>
                    <td>
                        <div>
                          <div className="tooltip p-padding-top-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="primary"
                                size="small"
                                onClick={() => {
                                  console.log(me.id)
                                  setSelectedExternalMeetingId(me.id)
                                  navigate('/externalmeetings/create')
                                }}
                              >
                                <RdsIcon
                                  name="pencil-fill"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">External Meeting Edit</span>
                            </div>
                          </div>
                          <div className="tooltip p-padding-top-10 p-padding-left-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="danger"
                                size="small"
                                onClick={() => onClickDelete(me.id)} //me.id
                              >
                                <RdsIcon
                                  name="trash"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">Delete External Meeting</span>
                            </div>
                          </div>
                        </div>
                      </td>
                  </tr>
                ))
              }
            </tbody>)
            : <tbody>
                {spinner ? <RdsSpinner size="medium" color="primary">Loading External Meetings</RdsSpinner> : "No entries"}
            </tbody>
          }
        </table>
      </main>
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>

      <div style={{display: "flex", flexDirection: "row"}}>
          <RdsButton size="medium" className="item" onClick={() => onArrowClick('first')}>&laquo;</RdsButton>
          &nbsp;
          {buttonList.map((bu) => ( //reverse().
            <RdsButton size="medium" key={`rds-button-${bu.value}`} className="item" variant={(pagination.selectedPage !== undefined && Number(pagination.selectedPage) === bu.value) ? 'secondary' : 'primary'} onClick={() => pageChange(bu.value)}>{bu.label}</RdsButton>
          ))}
          &nbsp;
          <RdsButton size="medium" className="item" onClick={() => onArrowClick('last')}>&raquo;</RdsButton>
          &nbsp;
          <RdsSelect
          className="rds-select-custom"
          options={JSON.stringify(pageOptions)}
          value={pagination.limit}
          onInput={(e: any) => {
            setPagination({
              ...pagination,
              limit: Number(e.target.value),
              selectedPage: 0,
            });
          }}
          style={{height:"20px"}}
          />


        
      </div>
      </div>
    </div>
  )
}

export default ExternalMeeting