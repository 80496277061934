import { RdsAnimation } from '@ramboll/rds-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

function ErrorPage() {
  const { setIsAuthenticated } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    // The page will be automatically be redirected with a delay
    setTimeout(() => {
      //console.log("setTimeout");
      console.log('setting isauthenticated false on error')
      sessionStorage.clear();
      setIsAuthenticated(false);
      navigate('/login');
    }, 1000);
  }, []);

  return (
    <div>
      <h1>Unauthenticated!</h1>
      <div
        style={{
          position: 'relative',
          overflow: 'clip',
          padding: '2rem',
          background: 'var(--r-color-primary-5)',
        }}
      >
        <RdsAnimation name="flying-plane" />
      </div>
    </div>
  );
}

export default ErrorPage;
