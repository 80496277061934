import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { RdsButton, RdsFieldset, RdsInput, RdsSelect, RdsSkeleton, RdsTooltip } from '@ramboll/rds-react';
import { IMeeting, IMeetingNotification, OptionObjectType, IMeetingMaterial } from '../../../types';
import { dummyMeeting, dummyMeetingNotification, dummyMeetingMaterial, makeDateAccordingToSelectDate, makeOptionsForInput, 
  returnSelectedValueForSelectList, makeDateReadable, makeDateTimeReadable, getLocalDateTime, getLocalDate, getLocalTime, findObjectById } from '../../../constant';
import './meetings.scss';
import { useAppContext } from '../../../context/AppContext';
import Select from 'react-select';
import { max } from 'lodash';


type Props = {}

function MeetingsForm(props: Props) {
  const navigate = useNavigate();
  const [meeting, setMeeting] = useState<IMeeting>(dummyMeeting)
  const [meetingTypeOptions, setMeetingTypeOptions] = useState<OptionObjectType[]>([])
  const [materialOptions, setMaterialOptions] = useState<OptionObjectType[]>([])
  const [notificationMethodOptions, setNotificationMethodOptions] = useState<OptionObjectType[]>([])
  const [stakeholderOptions, setStakeholderOptions] = useState<OptionObjectType[]>([])
  const [localNotifications, setLocalNotifications] = useState<IMeetingNotification[]>([])
  //const [notificationOptions, setNotificationOptions] = useState<OptionObjectType[]>([])

  const { meetingTypes, notificationMethods, stakeholders, users } = useAppContext()

  const { selectedMeetingId, setSelectedMeetingId } = useAppContext();

  useEffect(() => {
    setMeetingTypeOptions(makeOptionsForInput(meetingTypes, 'name', 'id'))
    setNotificationMethodOptions(makeOptionsForInput(notificationMethods, 'name', 'id'))
    setStakeholderOptions(makeOptionsForInput(stakeholders, 'name', 'id'))
  }, [meetingTypes, notificationMethods, stakeholders])

  useEffect(() => {
    if (selectedMeetingId !== 0) {
      api().meeting.getById(selectedMeetingId).then((me) => {
        console.log('Getting the Meeting by id')
        var notifications = me.notifications;
        for (let index = 0; index < notifications.length; index++) {
          notifications[index].notificationDistributionDate = getLocalDate(notifications[index].notificationDistributionDate);
        }
        setLocalNotifications(notifications)
        setMeeting(me)
        setMeeting((me) => ({
          ...me,
          date: getLocalDate(me.date),
          time: getLocalTime(me.time)
        }));
        console.log(me);
      }).catch(() => {
        navigate('/error')
      })

    }
    api().material.get().then((materials) => {
      console.log('Getting the materials')
      for (let index = 0; index < materials.length; index++){
        materials[index].nameVersion = materials[index].name + (materials[index].version !== null && materials[index].version !== "" ? (" (version " + materials[index].version + ")") : "")
      }
      if (materials.length > 0) {
        setMaterialOptions(makeOptionsForInput(materials, 'nameVersion', 'id'))
      }
    }).catch(() => {
      navigate('/error')
    })
  }, [])

  function addNotificationToList(): void {
    setLocalNotifications((c) => ([
      ...c,
      // { ...dummyCommentAction, id: localActions.length }
      { ...dummyMeetingNotification} //, id: uuidv4()
    ]))
    console.log(localNotifications)
  }

  function addLocalToActualMeeting(la: IMeetingNotification): void {
    var newId = Math.max(...localNotifications.map(o => o.id))+1;
    la.id = newId; 
    setMeeting((c) => ({
      ...c,
      notifications: [...meeting.notifications, la]
    }))
  }

  function deleteLocalToActualMeeting(la: IMeetingNotification): void {
    setMeeting((c) => ({
      ...c,
      notifications: [...meeting.notifications.filter((ca) => ca !== la)]
    }))
    setLocalNotifications((sl) => ([
      ...sl.filter((ca) => ca !== la)
    ]))
  }

  // const onMeetingSubmit = (() => {
  //   api().meeting.post(meeting).then((response) => {
  //     console.log(response)
  //     navigate('/meetings')
  //   })
  // })

  const onMeetingSubmit = (() => {
    setMeeting((me) => ({
      ...me,
      //date: new Date(e.target.value).toISOString(),
      //date: new Date(me.date).toISOString(),
    }));

    if (selectedMeetingId === 0) {
      api().meeting.post(meeting).then((response) => {
        setSelectedMeetingId(0)
        navigate('/meetings')
      })
    } else {
      api().meeting.edit(selectedMeetingId, meeting).then(() => {
        console.log('Meeting Edited')
        setTimeout(function () {
          navigate('/meetings')
        }, 2000); //Time before execution
        // toast.success("Meeting Updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }).catch((e) => {
        console.log(e)
        // toast.error("Meeting NOT edited. Check all inputs and click Submit again", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      })
    }
  })


  return (
    <div>

      <h1>Meetings</h1>
      <form>
        <RdsFieldset legend="Meeting Information" color="primary">
          <div className='p-flex'>
            {/* {!meeting.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            <RdsTooltip content="Enter the meeting name">
              <RdsInput
                style={{ width: '400px' }}
                className='p-margin-right-10'
                label='Name'
                value={meeting?.name}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    name: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Select the meeting type">
              <RdsSelect
                style={{ width: '300px' }}
                className='p-margin-right-10'
                label='Type'
                // name='type'
                options={JSON.stringify(meetingTypeOptions)}
                value={meeting.typeId}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    typeId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the number of meeting attendees. Do not include Cleco representatives and speakers.">
              <RdsInput
                style={{ width: '200px' }}
                className='p-margin-right-10'
                label="Number of Attendees"
                // name="Recipient Count"
                value={meeting?.attendeeCount?.toString()}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    attendeeCount: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            {/* )} */}
          </div>
          <div className='p-flex'>
            {/* {!meeting.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            <RdsTooltip content="Enter the meeting location">
              <RdsInput
                style={{ width: '400px' }}
                className='p-margin-right-10'
                label='Location'
                value={meeting?.location}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    location: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            {/* <div className='item__container_cp'>
              <DateTimePickerWrapperMeeting
                setMeeting={setMeeting}
                name={'Meeting Date and Time'}
                value={
                  meeting.date
                }
              /> 
            </div> */}
            {/* <RdsTooltip content="Enter the meeting date and time">
              <RdsInput
                style={{ width: '455px' }}
                className='p-margin-right-10'
                type="datetime-local"
                label='Date and Time'
                //value={meeting?.date}
                value={makeDateAccordingToSelectDate(meeting?.date)}
                onInput={(e: any) => {
                  var formattedDate = getLocalDateTime(e.target.value);
                  setMeeting((me) => ({
                    ...me,
                    //date: new Date(e.target.value).toISOString(),
                    date: formattedDate.toString(),
                  }));
                  console.log(meeting);
                }}
              />
            </RdsTooltip> */}
            <RdsTooltip content="Enter the meeting date">
              <RdsInput
                style={{ width: '300px' }}
                className='p-margin-right-10'
                type="date"
                label='Date'
                //value={meeting?.date}
                value={makeDateAccordingToSelectDate(meeting?.date)}
                onInput={(e: any) => {
                  var formattedDate = getLocalDate(e.target.value);
                  setMeeting((me) => ({
                    ...me,
                    //date: new Date(e.target.value).toISOString(),
                    date: formattedDate.toString(),
                  }));
                  console.log(meeting);
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the meeting time">
              <RdsInput
                style={{ width: '200px' }}
                className='p-margin-right-10'
                type="time"
                label='Time'
                //value={meeting?.date}
                value={meeting?.time}
                onInput={(e: any) => {

                  //var formattedDate = getLocalDate(e.target.value);
                  setMeeting((me) => ({
                    ...me,
                    //date: new Date(e.target.value).toISOString(),
                    time: e.target.value,
                  }));
                  console.log(e.target.value);
                  console.log(e.target.value.toString());
                  console.log(meeting);
                }}
              />
            </RdsTooltip>
            {/* </div> */}
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Select the stakeholder(s) at the meeting">
              <label className="check-container">
                Stakeholder(s)
                <div style={{ width: '455px' }} className='p-margin-right-10'>
                  <Select
                    options={stakeholderOptions}
                    value={returnSelectedValueForSelectList(meeting.stakeholders, stakeholderOptions)}
                    isSearchable
                    isMulti
                    onChange={(e: any) => {
                      const listOfStakeholders: number[] = []
                      for (let index = 0; index < e.length; index++) {
                        listOfStakeholders.push(e[index].value);
                      }
                      setMeeting((c) => ({
                        ...c,
                        stakeholders: listOfStakeholders
                      }))
                    }}
                  />
                </div>
              </label>
            </RdsTooltip>
            <RdsTooltip content="Select associated meeting material(s)">
              <label className="check-container">
                Meeting Materials
                <div style={{ width: '455px' }}>
                  <Select
                    options={materialOptions}
                    value={returnSelectedValueForSelectList(meeting.materials, materialOptions)}
                    isSearchable
                    isMulti
                    onChange={(e: any) => {
                      const listOfMaterials: number[] = []
                      for (let index = 0; index < e.length; index++) {
                        listOfMaterials.push(e[index].value);
                      }
                      setMeeting((c) => ({
                        ...c,
                        materials: listOfMaterials
                      }))
                    }}
                  />
                </div>
              </label>
            </RdsTooltip>
          </div>
        </RdsFieldset>
        {/* <RdsFieldset legend="Meeting Notification" color="primary">
          <div className='p-flex'> */}
            {/* {!meeting.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            {/* <RdsTooltip content="Enter the meeting notification distribution date">
              <RdsInput
                style={{ width: '400px' }}
                type="datetime-local"
                label='Distribution Date'
                // name='type'
                className='p-margin-right-10'
                value={makeDateAccordingToSelectDate(meeting?.notificationDistributionDate)}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    notificationDistributionDate: new Date(e.target.value).toISOString(),
                  }));
                }}
              />
            </RdsTooltip> */}
            {/* <RdsTooltip content="Select the meeting notification method">
              <RdsSelect
                style={{ width: '300px' }}
                label='Method'
                // name='type'
                className='p-margin-right-10'
                options={JSON.stringify(notificationMethodOptions)}
                value={meeting.notificationMethodId}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    notificationMethodId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip> */}

            {/* <RdsTooltip content="Enter the number of meeting notification recipients">
              <RdsInput
                style={{ width: '200px' }}
                label="Recipient Count"
                className='p-margin-right-10'
                name="Recipient Count"
                value={meeting?.notificationRecipientCount.toString()}
                onInput={(e: any) => {
                  setMeeting((me) => ({
                    ...me,
                    notificationRecipientCount: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip> */}
            {/* )} */}
          {/* </div>
        </RdsFieldset> */}


        <RdsFieldset legend="Meeting Notification" color="primary">
            {localNotifications
              // .sort((a, b) => a.actionId - b.commentId)
              .map((la) => (
                <div className='p-flex p-crossaxis-center'>
                  <RdsSelect
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Notification Method'
                    disabled={la.id !== 0}
                    // name='issue'
                    options={JSON.stringify(notificationMethodOptions)}
                    value={la.notificationMethodId}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      // Making sure we are editing when in edit mode
                      setLocalNotifications((sla) => ([
                        ...localNotifications.filter((l) => l !== la),
                        { ...la, notificationMethodId: Number(e.target.value) }
                      ]))
                      if (la.id!==0) {
                        setMeeting((sc) => ({
                          ...sc,
                          notifications: [
                            ...meeting.notifications.filter((l) => l !== la),
                            { ...la, notificationMethodId: Number(e.target.value) }
                          ]
                        }))
                      }
                    }
                    }
                  />
                  <RdsInput
                    type="date"
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Distribution Date'
                    disabled={la.id !== 0}
                    value={makeDateAccordingToSelectDate(la.notificationDistributionDate)}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      var formattedDate = getLocalDate(e.target.value);
                      if (la.id===0) {
                        setLocalNotifications((sla) => ([
                          ...localNotifications.filter((l) => l !== la),
                          { ...la, notificationDistributionDate: formattedDate.toString() }
                          //{ ...la, notificationDistributionDate: new Date(e.target.value).toISOString() }
                        ]))
                      } else {
                        setMeeting((sc) => ({
                          ...sc,
                          notifications: [
                            ...meeting.notifications.filter((l) => l !== la),
                            { ...la, notificationDistributionDate: formattedDate.toString() }
                            //{ ...la, notificationDistributionDate: new Date(e.target.value).toISOString() }
                          ]
                        }))
                      }
                    }}
                  />
                  <RdsInput
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Recipient Count'
                    disabled={la.id !== 0}
                    value={la.notificationRecipientCount?.toString()}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      if (la.id===0) {
                        setLocalNotifications((sla) => ([
                          ...localNotifications.filter((l) => l !== la),
                          { ...la, notificationRecipientCount: e.target.value }
                        ]))
                      } else {
                        setMeeting((sc) => ({
                          ...sc,
                          notifications: [
                            ...meeting.notifications.filter((l) => l !== la),
                            { ...la, notificationRecipientCount: e.target.value }
                          ]
                        }))
                      }
                    }}
                  />
                  {(!meeting.notifications.find((ac) => ac.id === la.id))
                    ? < RdsButton key={la.id} variant='primary' onClick={() => addLocalToActualMeeting(la)}>Save</RdsButton>
                    : < RdsButton key={la.id} variant='danger' className='' onClick={() => deleteLocalToActualMeeting(la)}>Delete</RdsButton>}
                </div>
              ))
            }
            <RdsButton onClick={addNotificationToList}>New Notification</RdsButton>
          </RdsFieldset>
          <RdsFieldset legend="" color="primary"/>
      </form>
      <RdsButton variant='primary' className='p-margin-right-10' onClick={onMeetingSubmit}>{selectedMeetingId > 0 ? 'Save Changes' : 'Submit'}</RdsButton>
      <RdsButton variant='secondary' onClick={() => { navigate('/meetings') }}>Cancel</RdsButton>
      {selectedMeetingId > 0 ? <div>Record Last Updated: {makeDateReadable(meeting?.timestamp)} by {findObjectById(users, meeting.userId) ? findObjectById(users, meeting.userId).email : ""}</div> : <div></div>}
      <br></br>
    </div>
  )
}

export default MeetingsForm