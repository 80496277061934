import React from 'react';
import './App.scss';
import {
  Route,
  Routes,
} from 'react-router-dom';

import { navlinks } from './constant';
import Login from './views/login';
import { useAppContext } from './context/AppContext';
import ErrorPage from './views/error';
import MeetingsForm from './views/meetings/meetingsForm';
import CommentsForm from './views/comments/commentsForm';
import MaterialForm from './views/material/materialForm';
import ExternalsForm from './views/externals/externalForm';
// import CreateProject from './views/projects/components/CreateProject';

function App() {
  const { isAuthenticated } = useAppContext();

  return (
    <div className="App">
      <Routes>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/error"} element={<ErrorPage />} />
        {(navlinks).map((navlink) =>
          <Route key={navlink.name} path={navlink.url} element={isAuthenticated && <navlink.element />} />
        )}
        <Route path={"/meetings/create"} element={<MeetingsForm />} />
        <Route path={"/comments/create"} element={<CommentsForm />} />
        <Route path={"/materials/create"} element={<MaterialForm />} />
        <Route path={"/externalmeetings/create"} element={<ExternalsForm />} />
      </Routes>
    </div>
  );
}

export default App;
