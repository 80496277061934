import { RdsDialog } from '@ramboll/rds-react'
import React from 'react'

type Props = {
  open: boolean,
  onDialogToggled: (e: { detail: boolean; }) => void,
  children: React.ReactNode;
  label: string,
  size: "small" | "medium" | "large" | undefined
  variant: "primary" | "danger" | "success" | undefined
}

const Modal = ({ open, onDialogToggled, label, size = 'large', variant = 'primary', children}: Props) => {
  return (
    <div>
      <RdsDialog
        open={open}
        variant={variant}
        size={size}
        label={label}
        onDialogToggled={onDialogToggled}
        vertically-centered
      >
        <div style={{
          //overflowY: 'scroll',
          // border: '1px solid red',
          width: '100%',
          float: 'left',
          maxHeight: '1000px',
          position: 'relative'
        }}>
          {children}
        </div>
      </RdsDialog>
    </div>
  )
}
export default Modal