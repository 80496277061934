import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { RdsButton, RdsFieldset, RdsInput, RdsSelect, RdsSkeleton, RdsTooltip, RdsCheckbox } from '@ramboll/rds-react';
import { IExternalMeeting, OptionObjectType } from '../../../types';
import { dummyExternalMeeting, makeDateAccordingToSelectDate, makeOptionsForInput, returnSelectedValueForSelectList, makeDateReadable, findObjectById, getLocalDate } from '../../../constant';
import { useAppContext } from '../../../context/AppContext';
import Select from 'react-select';


type Props = {}
function ExternalMeetings(props: Props) {
  const navigate = useNavigate();
  const [external, setExternalMeeting] = useState<IExternalMeeting>(dummyExternalMeeting)
  const [issueOptions, setIssueOptions] = useState<OptionObjectType[]>([])
  const [meetingFormatOptions, setMeetingFormatOptions] = useState<OptionObjectType[]>([])
  const [checkedWritten, setCheckedWritten] = useState(false);
  const [textWritten, setTextWritten] = useState("");
  const [checkedOral, setCheckedOral] = useState(false);
  const [textOral, setTextOral] = useState("");
  const { meetingFormats, issues, users } = useAppContext()

  const { selectedExternalMeetingId, setSelectedExternalMeetingId } = useAppContext();

  useEffect(() => {
    setMeetingFormatOptions(makeOptionsForInput(meetingFormats, 'name', 'id'))
    setIssueOptions(makeOptionsForInput(issues, 'name', 'id'))
  }, [meetingFormats, issues])

  useEffect(() => {
    if (selectedExternalMeetingId !== 0) {
      api().external.getById(selectedExternalMeetingId).then((co) => {
        console.log('Getting the External Meeting by Id')
        //setLocalActions(co.actions)
        setExternalMeeting(co)
        setExternalMeeting((co) => ({
          ...co,
          date: getLocalDate(co.date)
        }));
        setTextWritten(co.writtenCommentPath);
        setTextOral(co.oralCommentPath);
      }).catch(() => {
        navigate('/error')
      })
    }
  }, [])

  const onExternalMeetingSubmit = (() => {
    if (selectedExternalMeetingId === 0) {
      api().external.post(external).then((response) => {
        console.log(response)
        navigate('/externalmeetings')
      })
    } else {
      api().external.edit(selectedExternalMeetingId, external).then(() => {
        console.log('External Meeting Edited')
        setTimeout(function () {
          navigate('/externalmeetings')
        }, 2000); //Time before execution
        // toast.success("Material Updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }).catch((e) => {
        console.log(e)
        // toast.error("Meeting Material NOT edited. Check all inputs and click Submit again", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      })
    }
  })

  return (
    <div>
      <h1>External Meeting</h1>
      <form>
      <RdsFieldset legend="Meeting Information" color="primary">
          <div className='p-flex'>
            {/* {!meeting.name ? (
            <RdsSkeleton variant="rectangular" color="primary" width="100%" height="16px" />)
            : ( */}
            <RdsTooltip content="Enter the external meeting name">
              <RdsInput
                style={{ width: '400px' }}
                className='p-margin-right-10'
                label='Name'
                value={external?.name}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    name: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Select the external meeting format">
              <RdsSelect
                style={{ width: '300px' }}
                className='p-margin-right-10'
                label='Format'
                // name='type'
                options={JSON.stringify(meetingFormatOptions)}
                value={external.formatId}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    formatId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the external meeting date">
              <RdsInput
                style={{ width: '300px' }}
                className='p-margin-right-10'
                type="date"
                label='Date'
                value={makeDateAccordingToSelectDate(external?.date)}
                onInput={(e: any) => {
                  var formattedDate = getLocalDate(e.target.value);
                  console.log(formattedDate);
                  setExternalMeeting((me) => ({
                    ...me,
                    date: formattedDate.toString(),
                  }));
                  console.log(external);
                }}
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Enter the external meeting host">
              <RdsInput
                style={{ width: '400px' }}
                className='p-margin-right-10'
                label='Meeting Host'
                value={external?.host}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    host: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the Cleco representative(s) who attended the external meeting">
              <RdsInput
                style={{ width: '610px' }}
                className='p-margin-right-10'
                label='Cleco Representative(s)'
                value={external?.representatives}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    representatives: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
          <RdsTooltip content="Select the topics raised at the external meeting by non-Cleco attendees">
              <label className="check-container">
              Comment Topics from Other Attendees
                <div style={{ width: '1020px' }} className='p-margin-right-10'>
                  <Select
                    options={issueOptions}
                    value={returnSelectedValueForSelectList(external.issues, issueOptions)}
                    isSearchable
                    isMulti
                    onChange={(e: any) => {
                      const listOfIssues: number[] = []
                      for (let index = 0; index < e.length; index++) {
                        listOfIssues.push(e[index].value);
                      }
                      setExternalMeeting((me) => ({
                        ...me,
                        issues: listOfIssues
                      }))
                    }}
                  />
                </div>
              </label>
            </RdsTooltip>
          </div>
        </RdsFieldset>
        <RdsFieldset legend="Meeting Materials" color="primary">
          <div className='p-flex'>
          <RdsTooltip content="Enter the folder or file path of the external meeting material(s)">
              <RdsInput
                style={{ width: '1020px' }}
                className='p-margin-right-10'
                label='Material Path'
                value={external?.materialPath}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    materialPath: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Did Cleco submit written comments at the external meeting?">
              <RdsCheckbox 
                label="Written Comment?" 
                name="written" 
                checked={external?.writtenComment}
                onInput={
                  (e: any) => {
                    console.log("click written");
                    console.log((e.target as HTMLInputElement).checked);
                    setExternalMeeting((me) => ({
                      ...me,
                      writtenComment: (e.target as HTMLInputElement).checked,
                    }));
                    if(!e.target.checked){
                      setTextWritten('');
                      setCheckedWritten(false);
                      setExternalMeeting((me) => ({
                        ...me,
                        writtenCommentPath: "",
                      }));
                    } else if(e.target.checked) {
                      setCheckedWritten(true);
                    }
                  }
                }
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Enter the folder or file path of the written comment(s)">
              <RdsInput
                style={{ width: '1020px' }}
                className='p-margin-right-10'
                label='Written Comment Path'
                value={textWritten}
                disabled={external ? !external.writtenComment : !checkedWritten}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    writtenCommentPath: e.target.value,
                  }));

                }}
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
          <RdsTooltip content="Did Cleco submit oral comments at the external meeting?">
              <RdsCheckbox 
                label="Oral Comment?" 
                name="oral" 
                checked={external.oralComment}
                onInput={
                  (e: any) => {
                    console.log("click oral");
                    console.log((e.target as HTMLInputElement).checked);
                    setExternalMeeting((me) => ({
                      ...me,
                      oralComment: (e.target as HTMLInputElement).checked,
                    }));
                    if(!e.target.checked){
                      setTextOral('');
                      setCheckedOral(false);
                      setExternalMeeting((me) => ({
                        ...me,
                        oralCommentPath: "",
                      }));
                    } else if(e.target.checked) {
                      setCheckedOral(true);
                    }
                  }
                }
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Enter the folder or file path of the oral comment(s)">
              <RdsInput
                style={{ width: '1020px' }}
                className='p-margin-right-10'
                label='Oral Comment Path'
                value={textOral}
                disabled={external ? !external.oralComment : !checkedOral}
                onInput={(e: any) => {
                  setExternalMeeting((me) => ({
                    ...me,
                    oralCommentPath: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
          </div>
        </RdsFieldset>
        <RdsFieldset legend="" color="primary"/>
      </form>
      <RdsButton variant='primary' className='p-margin-right-10' onClick={onExternalMeetingSubmit}>{selectedExternalMeetingId > 0 ? 'Save Changes' : 'Submit'}</RdsButton>
      <RdsButton variant='secondary' onClick={() => { navigate('/externalmeetings') }}>Cancel</RdsButton>
      {selectedExternalMeetingId > 0 ? <div>Record Last Updated: {makeDateReadable(external?.timestamp)} by {findObjectById(users, external.userId) ? findObjectById(users, external.userId).email : ""}</div> : <div></div>}
      <br></br>
    </div>
  )
}
export default ExternalMeetings