import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, {
  createContext, ReactNode, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { IAction, ICommentMethod, IIssue, IMaterialType, IMeeting, IMeetingType, INotificationMethod, IStakeholder, IUser, IComment, IMeetingMaterial, ICountSummary, IMeetingMaterialSummary, IExternalMeeting, IMeetingFormat } from '../types';
//import { dummyUser } from '../constant';
import { loginRequest } from '../service/authConfig';

const dummyUser: IUser = {
  id: 0,
  name: '',
  lastName: '',
  email: '',
  userRoleId: 0
}

interface ContextInterface {
  isAuthenticated: boolean;
  setIsAuthenticated: (a: boolean) => void;
  currentUser: IUser; // Need to know the type of user
  accessToken: string;
  selectedCommentId: number;
  setSelectedCommentId: (a: number) => void;
  selectedMeetingId: number;
  setSelectedMeetingId: (a: number) => void;
  selectedMeetingMaterialId: number;
  setSelectedMeetingMaterialId: (a: number) => void;
  selectedExternalMeetingId: number;
  setSelectedExternalMeetingId: (a: number) => void;
  notificationMethods: INotificationMethod[],
  commentMethods: ICommentMethod[],
  stakeholders: IStakeholder[],
  issues: IIssue[],
  meetingTypes: IMeetingType[]
  actions: IAction[]
  materialTypes: IMaterialType[],
  meetingFormats: IMeetingFormat[],
  materials: IMeetingMaterial[],
  meetings: IMeeting[],
  externalMeetings: IExternalMeeting[],
  users: IUser[],
  commentIssueSummary: ICountSummary[],
  meetingMaterialSummary: IMeetingMaterialSummary[]
}

export const AppContext = createContext<ContextInterface>({
  isAuthenticated: false, // setting a default value
  setIsAuthenticated: () => { },
  currentUser: dummyUser,
  accessToken: '',
  selectedCommentId: 0,
  setSelectedCommentId: (a: number) => { },
  selectedMeetingId: 0,
  setSelectedMeetingId: (a: number) => { },
  selectedMeetingMaterialId: 0,
  setSelectedMeetingMaterialId: (a: number) => { },
  selectedExternalMeetingId: 0,
  setSelectedExternalMeetingId: (a: number) => { },
  notificationMethods: [],
  commentMethods: [],
  stakeholders: [],
  materials: [],
  issues: [],
  meetingTypes: [],
  actions: [],
  materialTypes: [],
  meetingFormats: [],
  meetings: [],
  externalMeetings: [],
  users: [],
  commentIssueSummary: [],
  meetingMaterialSummary: []
});

export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }: { children: ReactNode; }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUser>(dummyUser);
  const [accessToken, setAccessToken] = useState<string>('');
  const [notificationMethods, setNotificationMethods] = useState<INotificationMethod[]>([]);
  const [commentMethods, setCommentMethods] = useState<ICommentMethod[]>([]);
  const [stakeholders, setStakeholders] = useState<IStakeholder[]>([]);
  const [issues, setIssues] = useState<IIssue[]>([]);
  const [meetingTypes, setMeetingTypes] = useState<IMeetingType[]>([]);
  const [meetingFormats, setMeetingFormats] = useState<IMeetingFormat[]>([]);
  const [actions, setActions] = useState<IAction[]>([]);
  const [materialTypes, setMaterialTypes] = useState<IMaterialType[]>([]);
  const [comments, setComments] = useState<IComment[]>([]);
  const [meetings, setMeetings] = useState<IMeeting[]>([]);
  const [materials, setMeetingMaterials] = useState<IMeetingMaterial[]>([]);
  const [externalMeetings, setExternalMeetings] = useState<IExternalMeeting[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [commentIssueSummary, setCommentIssueSummary] = useState<ICountSummary[]>([]);
  const [commentMethodSummary, setCommentMethodSummary] = useState<ICountSummary[]>([]);
  const [commentStakeholderSummary, setCommentStakeholderSummary] = useState<ICountSummary[]>([]);
  const [commentActionSummary, setCommentActionSummary] = useState<ICountSummary[]>([]);
  const [meetingDateSummary, setMeetingDateSummary] = useState<ICountSummary[]>([]);
  const [meetingTypeSummary, setMeetingTypeSummary] = useState<ICountSummary[]>([]);
  const [meetingTimeSummary, setMeetingTimeSummary] = useState<ICountSummary[]>([]);
  const [meetingMaterialSummary, setMeetingMaterialSummary] = useState<IMeetingMaterialSummary[]>([]);
  // const [users, setUsers] = useState<IUser[]>([]);
  const [selectedCommentId, setSelectedCommentId] = useState<number>(0);
  const [selectedMeetingId, setSelectedMeetingId] = useState<number>(0);
  const [selectedMeetingMaterialId, setSelectedMeetingMaterialId] = useState<number>(0);
  const [selectedExternalMeetingId, setSelectedExternalMeetingId] = useState<number>(0);

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const isSessionAuthenticated = useIsAuthenticated();

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which
    // is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response: { accessToken: string }) => {
      localStorage.setItem('accessToken', '');
      setAccessToken(response.accessToken);
      localStorage.setItem('accessToken', response.accessToken);
      // setIsAuthenticated(true);
    }).catch((e: any) => {
      localStorage.clear();
      sessionStorage.clear();
      console.log(e);
      instance.acquireTokenPopup(request).then((response: { accessToken: string }) => {
        setAccessToken(response.accessToken);
        localStorage.setItem('accessToken', '');
        localStorage.setItem('accessToken', response.accessToken);
        // setIsAuthenticated(true);
      });
    });
  }
  const value = React.useMemo(() => ({
    isAuthenticated, setIsAuthenticated, currentUser, accessToken, notificationMethods, commentMethods, stakeholders, issues, meetingTypes, meetingFormats, actions, materialTypes, meetings, materials, externalMeetings,
    selectedCommentId, setSelectedCommentId, selectedMeetingId, setSelectedMeetingId, selectedMeetingMaterialId, setSelectedMeetingMaterialId, selectedExternalMeetingId, setSelectedExternalMeetingId, users, commentIssueSummary, meetingMaterialSummary
  }), [isAuthenticated, currentUser, accessToken, notificationMethods, commentMethods, stakeholders, issues, meetingTypes, actions, materialTypes, meetings, materials, externalMeetings, selectedCommentId, setSelectedCommentId, selectedMeetingId, 
    setSelectedMeetingId, selectedMeetingMaterialId, setSelectedMeetingMaterialId, selectedExternalMeetingId, setSelectedExternalMeetingId, users, commentIssueSummary, meetingMaterialSummary]);

  const setContext = async () => {
    console.log('begin setContext');
    api().comment.get().then((c) => {
      console.log('Getting the comments')
      setComments(c);
    })
    api().meeting.get().then((m) => {
      console.log('Getting the meetings')
      setMeetings(m);
    })
    api().material.get().then((m) => {
      console.log('Getting the meeting materials')
      setMeetingMaterials(m);
    })
    api().external.get().then((m) => {
      console.log('Getting the external meetings')
      setExternalMeetings(m);
    })
    api().info.currentUser().then((cu) => {
      console.log('Getting the user details')
      setCurrentUser(cu[0]);
    })
    api().info.notificationMethod().then((m) => {
      console.log('Getting the notification meetings')
      setNotificationMethods(m)
    })
    api().info.commentMethod().then((m) => {
      console.log('Getting the comment method')
      setCommentMethods(m)
    })
    api().info.stakeholders().then((m) => {
      console.log('Getting the stakeholders')
      setStakeholders(m)
    })
    api().info.issue().then((m) => {
      console.log('Getting the issues')
      setIssues(m)
    })
    api().info.action().then((m) => {
      console.log('Getting the action')
      setActions(m)
    })
    api().info.meetingType().then((m) => {
      console.log('Getting the meeting type')
      setMeetingTypes(m)
    })
    api().info.meetingFormat().then((m) => {
      console.log('Getting the meeting format')
      setMeetingFormats(m)
    })
    api().info.materialTypes().then((m) => {
      console.log('Getting the materialTypes')
      setMaterialTypes(m)
    })
    api().info.users().then((m) => {
      console.log('Getting the users')
      setUsers(m)
    })
    api().dashboard.commentIssueSummary().then((m) => {
      console.log('Getting the summary')
      setCommentIssueSummary(m)
    })
    api().dashboard.commentMethodSummary().then((m) => {
      console.log('Getting the summary')
      setCommentMethodSummary(m)
    })
    api().dashboard.commentStakeholderSummary().then((m) => {
      console.log('Getting the summary')
      setCommentStakeholderSummary(m)
    })
    api().dashboard.commentActionSummary().then((m) => {
      console.log('Getting the summary')
      setCommentActionSummary(m)
    })
    api().dashboard.meetingDateSummary().then((m) => {
      console.log('Getting the summary')
      setMeetingDateSummary(m)
    })
    api().dashboard.meetingTypeSummary().then((m) => {
      console.log('Getting the summary')
      setMeetingTypeSummary(m)
    })
    api().dashboard.meetingTimeSummary().then((m) => {
      console.log('Getting the summary')
      setMeetingTimeSummary(m)
    })
    api().dashboard.meetingMaterialSummary().then((m) => {
      console.log('Getting the summary')
      setMeetingMaterialSummary(m)
    })
  }
  useEffect(() => {
    setContext()
  }, [])

  useEffect(() => {
    const testAuthorization = async () => {
      await setContext()
    }

    if (isSessionAuthenticated === true) {
      console.log('User is authenticated');
      // Getting the requestToken
      RequestAccessToken();
      // Getting the current user
      if (accessToken !== '') {
        console.log('Found the token')

        testAuthorization().then(() => {
          setIsAuthenticated(true);
          navigate('/')
        })
          .catch((e) => {
            setIsAuthenticated(false);
            if (!window.location.href.includes('/error')) {
              // window.location.href = '/';
              console.log('navigating to login')
              navigate('/login');
            }
          })
      }
    } else if (isSessionAuthenticated === false) {
      if (isAuthenticated === true) {
        RequestAccessToken();
      } else {
        if (!window.location.href.includes('/error')) {
          // window.location.href = '/';
          console.log('navigating to login')
          navigate('/error');
        }
        // console.log("navigationing ......")
        // navigate('/login');
      }
    }
  }, [isSessionAuthenticated, accessToken]);

  // Get the TOKEN MANAGER
  // Some useEffect for authentication

  return (
    <AppContext.Provider
      value={value}
    >
      {children}
    </AppContext.Provider>
  );


}
