import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { RdsButton, RdsFieldset, RdsInput, RdsSelect, RdsSkeleton, RdsTooltip, RdsTextarea } from '@ramboll/rds-react';
import { useAppContext } from '../../../context/AppContext';
import { IComment, ICommentAction, OptionObjectType } from '../../../types';
import { dummyComment, dummyCommentAction, makeDateAccordingToSelectDate, makeDateReadable, makeOptionsForInput, findObjectById, getLocalDate } from '../../../constant';
import Select from 'react-select';

type Props = {}

function Comments(props: Props) {
  const navigate = useNavigate();
  const [comment, setComment] = useState<IComment>(dummyComment)
  const [commentMethodOptions, setCommentMethodOptions] = useState<OptionObjectType[]>([])
  const [stakeholderOptions, setStakeholderOptions] = useState<OptionObjectType[]>([])
  const [issueOptions, setIssueOptions] = useState<OptionObjectType[]>([])
  const [meetingOptions, setMeetingOptions] = useState<OptionObjectType[]>([])
  const [actionOptions, setActionOptions] = useState<OptionObjectType[]>([])
  const [localActions, setLocalActions] = useState<ICommentAction[]>([])
  const [userOptions, setUserOptions] = useState<OptionObjectType[]>([])
  const existingComment = (comment != null);

  const { actions, issues, stakeholders, commentMethods, users } = useAppContext()

  const { selectedCommentId, setSelectedCommentId } = useAppContext();

  useEffect(() => {
    api().meeting.get().then((meetings) => {
      console.log('Getting the Meeting')
      if (meetings.length > 0) {
        setMeetingOptions(makeOptionsForInput(meetings, 'name', 'id'))
      }
    }).catch(() => {
      navigate('/error')
    })
    setActionOptions(makeOptionsForInput(actions, 'name', 'id'))
    setCommentMethodOptions(makeOptionsForInput(commentMethods, 'name', 'id'))
    setStakeholderOptions(makeOptionsForInput(stakeholders, 'name', 'id'))
    setIssueOptions(makeOptionsForInput(issues, 'name', 'id'))
    setUserOptions(makeOptionsForInput(users, 'email', 'id'))
  }, [issues, stakeholders, commentMethods, users])


  useEffect(() => {
    if (selectedCommentId !== 0) {
      api().comment.getById(selectedCommentId).then((co) => {
        console.log('Getting the Comment by Id')

        var actions = co.actions;
        for (let index = 0; index < actions.length; index++) {
          actions[index].actionDate = getLocalDate(actions[index].actionDate);
        }
        setLocalActions(actions)
        setComment(co)
        setComment((co) => ({
          ...co,
          commentReceivedDate: getLocalDate(co.commentReceivedDate)
        }));
      }).catch(() => {
        navigate('/error')
      })
    }
  }, [])

  function addActionToList(): void {
    setLocalActions((c) => ([
      ...c,
      // { ...dummyCommentAction, id: localActions.length }
      { ...dummyCommentAction} //, id: uuidv4() 
    ]))
    //console.log(localActions)
  }

  function addLocalToActualComment(la: ICommentAction): void {
    var newId = Math.max(...localActions.map(o => o.id))+1;
    la.id = newId;
    setComment((c) => ({
      ...c,
      actions: [...comment.actions, la]
    }))
  }

  function deleteLocalToActualComment(la: ICommentAction): void {
    setComment((c) => ({
      ...c,
      actions: [...comment.actions.filter((ca) => ca !== la)]
    }))
    setLocalActions((sl) => ([
      ...sl.filter((ca) => ca !== la)
    ]))
  }

  // const onCommentSubmit = (() => {
  //   api().comment.post(comment).then((response) => {
  //     navigate('/comments')
  //   })
  // })

  const onCommentSubmit = (() => {
    if (selectedCommentId === 0) {
      api().comment.post(comment).then((response) => {
        //console.log(response)
        navigate('/comments')
      })
    } else {
      api().comment.edit(selectedCommentId, comment).then(() => {
        console.log('Comment Edited')
        setTimeout(function () {
          navigate('/comments')
        }, 2000); //Time before execution
        // toast.success("Comment Updated", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      }).catch((e) => {
        console.log(e)
        // toast.error("Comment NOT edited. Check all inputs and click Submit again", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      })
    }
  })


  return (
    <div>

      <h1>Comment Insert</h1>
      <form>
        <RdsFieldset legend="Comment" color="primary">
          <RdsTooltip content="Enter the comment text">
            <RdsTextarea
              style={{ width: '920px' }}
              value={comment?.comment}
              onInput={(e: any) => {
                setComment((me) => ({
                  ...me,
                  comment: e.target.value,
                }));
              }}
            />
          </RdsTooltip>
        </RdsFieldset>
        <RdsFieldset legend="Response" color="primary">
          <RdsTooltip content="Enter the response to the comment">
            <RdsTextarea
              style={{ width: '920px' }}
              value={comment?.response}
              onInput={(e: any) => {
                setComment((me) => ({
                  ...me,
                  response: e.target.value,
                }));
              }}
            />
          </RdsTooltip>
        </RdsFieldset>
        <RdsFieldset legend="Commenter Information" color="primary">
          <div className='p-flex'>
            <RdsTooltip content="Enter the commenter's name">
              <RdsInput
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Commenter'
                value={comment?.commenter}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    commenter: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Select the stakeholder associated with the commenter">
              <RdsSelect
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Stakeholder'
                // name='stakeholder'
                options={JSON.stringify(stakeholderOptions)}
                value={comment.stakeholderId}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    stakeholderId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
          </div>
          <div className='p-flex'>
            <RdsTooltip content="Enter the commenter's contact information">
              <RdsTextarea
                style={{ width: '920px' }}
                className='p-margin-right-10'
                label='Contact Information'
                value={comment?.contactInformation}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    contactInformation: e.target.value,
                  }));
                }}
              />
            </RdsTooltip>
          </div>
        </RdsFieldset>
        <RdsFieldset legend="Comment Information" color="primary">
          <div className='p-flex'>
          <RdsTooltip content="Select the comment method">
              <RdsSelect
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Method'
                // name='commentMethod'
                options={JSON.stringify(commentMethodOptions)}
                value={comment.commentMethodId}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    commentMethodId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Enter the date the comment was received">
              <RdsInput
                type="date"
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Received Date'
                //value={makeDateReadable(comment?.commentReceivedDate)}
                value={makeDateAccordingToSelectDate(comment?.commentReceivedDate)}
                onInput={(e: any) => {
                  //console.log(e.target.value);
                  var formattedDate = getLocalDate(e.target.value);
                  //console.log(formattedDate);
                  setComment((me) => ({
                    ...me,
                    //commentReceivedDate: new Date(e.target.value).toISOString(),
                    commentReceivedDate: formattedDate.toString()
                  }));
                }}
              />
            </RdsTooltip>
            {/* )} */}
          </div>
          <div className='p-flex'>

            <RdsTooltip content="Select the issue associated with the comment">
              <RdsSelect
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Issue'
                // name='issue'
                options={JSON.stringify(issueOptions)}
                value={comment.issueId}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    issueId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            <RdsTooltip content="Select the meeting in which the comment was submitted (if applicable)">
              <RdsSelect
                style={{ width: '455px' }}
                className='p-margin-right-10'
                label='Meeting'
                // name='issue'
                options={JSON.stringify(meetingOptions)}
                value={comment.meetingId}
                onInput={(e: any) => {
                  setComment((me) => ({
                    ...me,
                    meetingId: Number(e.target.value),
                  }));
                }}
              />
            </RdsTooltip>
            {/* )} */}
          </div>
          <RdsFieldset legend="Actions" color="primary">
            {localActions
              // .sort((a, b) => a.actionId - b.commentId)
              .map((la) => (
                <div className='p-flex p-crossaxis-center'>
                  <RdsSelect
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Action'
                    disabled={la.id !== 0}
                    // name='issue'
                    options={JSON.stringify(actionOptions)}
                    value={la.actionId}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      // Making sure we are editing when in edit mode
                      setLocalActions((sla) => ([
                        ...localActions.filter((l) => l !== la),
                        { ...la, actionId: Number(e.target.value) }
                      ]))
                      console.log("action")
                      //console.log(la.id)
                      if (la.id !==0) {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, actionId: Number(e.target.value) }
                          ]
                        }))
                      }
                    }
                    }
                  />

                  <RdsInput
                    type="date"
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Date Initiated'
                    disabled={true}
                    value = {makeDateAccordingToSelectDate(la.actionDate)}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      var formattedDate = getLocalDate(e.target.value);
                      if (la.id===0) {
                        setLocalActions((sla) => ([
                          ...localActions.filter((l) => l !== la),
                          { ...la, actionDate: formattedDate.toString() }
                        ]))
                      } else {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, actionDate: formattedDate.toString() }
                          ]
                        }))
                      }
                    }}
                  />



                  {/* <RdsInput
                    type="date"
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Date'
                    value={makeDateReadable(la.actionDate)}
                    onInput={(e: any) => {
                      const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      var formattedDate = getLocalDate(e.target.value);
                      // Making sure we are editing when in edit mode
                      setLocalActions((sla) => ([
                        ...localActions.filter((l) => l !== la),
                        { ...la, actionDate: formattedDate.toString() }
                        //{ ...la, actionDate: new Date(e.target.value).toISOString() }
                      ]))
                      console.log("date")
                      console.log(la.id)
                      if (!re.test(la.id)) {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, actionDate: formattedDate.toString() }
                            //{ ...la, actionDate: new Date(e.target.value).toISOString() }
                          ]
                        }))
                      }
                    }
                    }
                  /> */}
                  <RdsSelect
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Assignee'
                    disabled={la.id !== 0}
                    options={JSON.stringify(userOptions)}
                    value={la.userId}
                    onInput={(e: any) => {
                      //const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      // Making sure we are editing when in edit mode
                      setLocalActions((sla) => ([
                        ...localActions.filter((l) => l !== la),
                        { ...la, userId: Number(e.target.value) }
                      ]))
                      //console.log("assignee")
                      //console.log(la.id)
                      if (la.id!==0) {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, userId: Number(e.target.value) }
                          ]
                        }))
                      }
                    }
                    }
                  />

                  {/* <RdsInput
                    type="date"
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Date'
                    value={makeDateReadable(la.actionDate)}
                    onInput={(e: any) => {
                      const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/;
                      if (re.test(la.id)) {
                        setLocalActions((sla) => ([
                          ...localActions.filter((l) => l !== la),
                          { ...la, actionDate: new Date(e.target.value).toISOString() }
                        ]))
                      } else {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, actionDate: new Date(e.target.value).toISOString() }
                          ]
                        }))
                      }
                    }}
                  /> */}
                  {/* <RdsSelect
                    style={{ width: '300px' }}
                    className='p-margin-right-10'
                    label='Assigned To'
                    // name='issue'
                    options={JSON.stringify(userOptions)}
                    value={la.userId}
                    onInput={(e: any) => {
                      const re = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
                      // Making sure we are editing when in edit mode
                      setLocalActions((sla) => ([
                        ...localActions.filter((l) => l !== la),
                        { ...la, userId: Number(e.target.value) }
                      ]))
                      if (!re.test(la.id)) {
                        setComment((sc) => ({
                          ...sc,
                          actions: [
                            ...comment.actions.filter((l) => l !== la),
                            { ...la, userId: Number(e.target.value) }
                          ]
                        }))
                      }
                    }
                    }
                  /> */}
                  {(!comment.actions.find((ac) => ac.id === la.id))
                    ? < RdsButton key={la.id} variant='primary' onClick={() => addLocalToActualComment(la)}>Save</RdsButton>
                    : < RdsButton key={la.id} variant='danger' className='' onClick={() => deleteLocalToActualComment(la)}>Delete</RdsButton>}
                </div>
              ))
            }
            <RdsButton onClick={addActionToList}>New Action</RdsButton>
          </RdsFieldset>
          {/* <label className="check-container">
            Action
            <div style={{ width: '455px' }}>
              <Select
                options={actionOptions}
                //value={returnSelectedValueForSelectList(tableFilter?.equals?.taskTypeId, taskTypesOption)}
                isSearchable
                isMulti
                onChange={(e) => {
                  const listOfActions: number[] = []
                  for (let index = 0; index < e.length; index++) {
                    listOfActions.push(e[index].value);
                  }
                  setComment((c) => ({
                    ...c,
                    actions: listOfActions
                  }))
                }}
              />
            </div>
          </label> */}
        </RdsFieldset>
        <RdsFieldset legend="" color="primary"/>
      </form>
      <RdsButton variant='primary' className='p-margin-right-10' onClick={onCommentSubmit}>{selectedCommentId > 0 ? 'Save Changes' : 'Submit'}</RdsButton>
      <RdsButton variant='secondary' onClick={() => { navigate('/comments') }}>Cancel</RdsButton>
      {/* <RdsButton onClick={onCommentSubmit}>Submit</RdsButton> */}
      {selectedCommentId > 0 ? <div>Record Last Updated: {makeDateReadable(comment?.timestamp)} by {findObjectById(users, comment.userId) ? findObjectById(users, comment.userId).email : ""}</div> : <div></div>}
      <br></br>
    </div >
  )
}

export default Comments