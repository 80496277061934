import React, { useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

type Props = {
  onChange: (timeValue: any) => void
  name: string
  defaultValue?: string
}

const TimePickerTask = ({ onChange, name, defaultValue = new Date().toISOString().substring(0, 10) }: Props) => {
  console.log("DefaultValue " + defaultValue);
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs(new Date().toISOString().substring(0, 10)),
  );

  useEffect(() => {
    setValue(dayjs(defaultValue))
  }, [defaultValue])

  const handleChange = (newValue: Dayjs | null) => {
    onChange(newValue?.format('YYYY-MM-DDTHH:mm')) //?.toISOString()
    setValue(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopTimePicker
        label={name}
        //inputFormat="MM/DD/YYYY"
        value={value}
        onChange={handleChange}
        //renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export default TimePickerTask;