import { RdsButton, RdsIcon } from '@ramboll/rds-react';
import React from 'react'
import './dropdown.scss'

export const DropDown = (children: React.ReactNode, headerTitle: string, filter1: string, filter2: string) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className='dropdown'>
      <div className="div__th">
        <div>
          {headerTitle}
        </div>
        <RdsButton onClick={handleOpen} variant={filter1 || filter2 ? 'secondary' : 'tertiary'} size="small" aria-labelledby="projectTitle">
          <RdsIcon name="menu-kebab" size="small" />
        </RdsButton>
        <div className={open ? 'dropdown-content dropdown-content-open' : 'dropdown-content dropdown-content-close'}>
          {open ? (
            <div className="p-flex p-flex-column p-crossaxis-center r-pt-m r-pb-m r-pl-xxl r-pr-xxl">
              {children}
            </div>
          ) : ""}
        </div>
      </div>
    </div>
  )
}