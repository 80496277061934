import React, { useEffect } from 'react';
import './index.scss';
import { RdsButton, RdsLogin } from '@ramboll/rds-react';
// Azure AD
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../service/authConfig';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import api from '../../api';

function Login() {
    const { instance } = useMsal();
    const { setIsAuthenticated } = useAppContext()
    const navigate = useNavigate();

    function handleLogin(inst: any) {
        inst.loginPopup(loginRequest).catch((e: any) => {
            console.error(e);
        });
    }
    useEffect(() => {
        api().info.currentUser().then((cu) => {
            console.log('User is authorized in login')
            navigate('/')
            setIsAuthenticated(true)
        }).catch(() => console.log('User Not Authorized'))
    }, [])

    return (
        <div className="welcomePage_main">
            {/* <h1>E&H Tracker App by Ramboll</h1> */}
            <RdsLogin brand="Cleco" heading="Login">
                <RdsButton variant="secondary" onClick={() => handleLogin(instance)}>with Ramboll credentials</RdsButton>
            </RdsLogin>
        </div>
    );
}

export default Login;
