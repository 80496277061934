import React, { ReactNode } from 'react'
import { useAppContext } from '../../context/AppContext';
import { INavlinks } from '../../types';
import Navbar from '../navbar';
import './layout.scss';

type Props = {
  children: ReactNode
  navlinks: INavlinks[]
}

const Layout = ({ children, navlinks }: Props) => {
  const { isAuthenticated } = useAppContext();

  return (
    <div>
      {isAuthenticated && (
        <Navbar navlinks={navlinks} />
      )}
      <div className={isAuthenticated ? 'r-p-m' : ''}>
        {children}
      </div>
      {isAuthenticated && (
        <footer>
          {`© ${new Date().getFullYear()} Ramboll `}
          <a href="https://ramboll.com/terms-of-use-and-privacy-policy">Privacy Policy</a>
        </footer>
      )}
    </div>
  )
}

export default Layout;