import React, { useEffect } from 'react'
import DatePickerTask from '../../../constant/components/datepicker/DatePicker'
import { ITableFilters } from '../../../types'

type Props = {
  tableFilter: ITableFilters
  setTableFilter: React.Dispatch<React.SetStateAction<ITableFilters>>
  name?: string
  attribute: 'generationDate',
  defaultValue: string,
  condition: 'greater' | 'lesser'
}


export const DatePickerWrapperFilter = ({ setTableFilter, name = '', attribute, defaultValue, tableFilter, condition }: Props) => {

  const onChange = (dateValue: string) => {
    // setEvents((st) => ({
    //   ...st,
    //   [attribute]: dateValue
    // }));
    // setEvents((ses) => ([
    //   ...ses.filter((es) => es !== event),
    //   {
    //     ...event,
    //     [attribute]: dateValue
    //   }
    // ]))

    //const cloneFilter: ITableFilters = JSON.parse(JSON.stringify(tableFilter));

    const cloneFilter: ITableFilters = JSON.parse(JSON.stringify(tableFilter));
    //const valueFromDropDownList = state.find((c) => c.value === Number(value));
    cloneFilter[condition][attribute] = dateValue.split('T')[0];
    setTableFilter({ ...cloneFilter });

  }
  return (
    <DatePickerTask onChange={onChange} name={name} defaultValue={defaultValue} />
  )
}